import dialogPolyfill from 'dialog-polyfill';

const donateIframe = document.getElementById("donate-ifr");
const modalTrigger = document.querySelector('.js-launch-modal');
const modalOnetime = document.querySelector('.js-modal-onetime');
const modalMonthly = document.querySelector('.js-modal-monthly');
const modalFromNav = document.querySelector('.js-modal-nav');
const navModalTrigger = document.querySelector('.js-launch-modal-from-nav');

// polyfills
if (modalTrigger || navModalTrigger) {
  if (modalOnetime) {
    dialogPolyfill.registerDialog(modalOnetime);
  }
  if (modalMonthly) {
    dialogPolyfill.registerDialog(modalMonthly);
  }
  if (modalFromNav) {
    dialogPolyfill.registerDialog(modalFromNav);
  }
}


// standard entry point - opening modal from quick-donate-widget-block
if (modalTrigger) {

  let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  let eventer = window[eventMethod];
  let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

  let selectedMonthly = false;
  // opening the modal
  modalTrigger.addEventListener('click', () => {
    selectedMonthly = document.querySelector('.js-donate-monthly').checked;
    const donation = document.querySelector('.js-donate-value').value;
    const donationURL = (donation > 9) ? "?d2sa=" + donation : "";

    //const formattedDonation = parseFloat(donation).toFixed(0);

    // prevent background scroll
    document.querySelector('body').classList.add('modal-open');
    document.querySelector('html').classList.add('modal-open');

    if (selectedMonthly) {
      // show monthly modal, prefill formattedDonation
      modalMonthly.showModal();
      let iframe = document.getElementById('navModalMonthly-ifr');
      iframe.setAttribute('src', "https://give.colonialwilliamsburg.org/y2L0kg" + donationURL);

      iframe.addEventListener("load", function () {
        getDonorFormData(iframe);
        //getDonorAmount(iframe, formattedDonation);
      });

      // Listen to message from child window
      eventer(messageEvent, function (e) {
          if(e.origin.startsWith('https://give.colonialwilliamsburg.org')) {
            modalMonthly.scroll(0, 0);
          }
      }, false);


    } else {
      // show onetime modal, prefill formattedDonation
      modalOnetime.showModal();
      let iframe = document.getElementById('navModalOneTime-ifr');
      iframe.setAttribute('src', "https://give.colonialwilliamsburg.org/ZDoFLL" + donationURL);

      iframe.addEventListener("load", function () {
        getDonorFormData(iframe);
        //getDonorAmount(iframe, formattedDonation);
      });

      // Listen to message from child window
      eventer(messageEvent, function (e) {
        if(e.origin.startsWith('https://give.colonialwilliamsburg.org')) {
          modalOnetime.scroll(0, 0);
        }
      }, false);
    }
  });

  // closing the modal
  const onClose = () => {
    // clear the form and manually wipe invalid classes and error messages since they persist for some reason
    let form;
    if (selectedMonthly) {
      let iframe = document.getElementById('navModalMonthly-ifr');
      //iframe.setAttribute('src', "");
      closeModalIframe(iframe);
    } else {
      let iframe = document.getElementById('navModalOneTime-ifr');
      //iframe.setAttribute('src', "");
      closeModalIframe(iframe);
    }

    // turn off modal styles
    document.querySelector('body').classList.remove('modal-open');
    document.querySelector('html').classList.remove('modal-open');
    // for accessibility: return focus to the trigger button
    modalTrigger.focus();
  };

  const closeButtons = document.querySelectorAll('.js-modal-close');
  Array.from(closeButtons).forEach((button) => {
    button.addEventListener('click', () => {
      if (modalMonthly.open) {
        modalMonthly.close();
      }
      if (modalOnetime.open) {
        modalOnetime.close();
      }
    });
  });
  modalMonthly.addEventListener('close', onClose);
  modalOnetime.addEventListener('close', onClose);
}

// nav entry point - opening modal from "Donate" link
if (navModalTrigger) {
  // Create IE + others compatible event handler
  let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  let eventer = window[eventMethod];
  let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

  // Listen to message from child window
  eventer(messageEvent, function (e) {
      if(e.origin.startsWith('https://give.colonialwilliamsburg.org')) {
        modalFromNav.scroll(0, 0);
      }
  }, false);


  // opening the modal
  const launchModal = () => {
    // prevent background scroll and show modal BEFORE Abila loads (prevent page jump)
    document.querySelector('body').classList.add('modal-open');
    document.querySelector('html').classList.add('modal-open');
    modalFromNav.showModal();
    let iframe = document.getElementById('navModal-ifr');
    iframe.setAttribute('src', "https://give.colonialwilliamsburg.org/8mvKO4");

    iframe.addEventListener("load", function () {
      getDonorFormData(iframe);
    });

    // closing the modal
    const onClose = () => {
      let iframe = document.getElementById('navModal-ifr');
      //iframe.setAttribute('src', "");
      closeModalIframe(iframe);
      // turn off modal styles
      document.querySelector('body').classList.remove('modal-open');
      document.querySelector('html').classList.remove('modal-open');
      // for accessibility: return focus to the trigger button
      navModalTrigger.focus();
    };

    const closeButtons = document.querySelectorAll('.js-modal-close');
    Array.from(closeButtons).forEach((button) => {
      button.addEventListener('click', () => {
        modalFromNav.close();
      });
    });
    modalFromNav.addEventListener('close', onClose);
  };

  navModalTrigger.addEventListener('click', launchModal);
  navModalTrigger.addEventListener('keypress', (e) => {
    if (e.key === 'Enter' || e.which === 13) {
      launchModal();
    }
  });
}

// helpers
function closeModalIframe(iframe) {
  //iframe.contentWindow.postMessage({COOKIE: true,}, '*');
  //setTimeout(() => iframe.setAttribute('src', ""), 1000);
  iframe.setAttribute('src', "");
}

function acronymToFullName(acronym) {
  let data = {
    AZ: 'Arizona',
    AL: 'Alabama',
    AK: 'Alaska',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'Washington, D.C.',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
    AS: "American Samoa",
    GU: "Guam",
    MP: "Northern Mariana Islands",
    PR: "Puerto Rico",
    VI: "U.S. Virgin Islands",
    UM: "U.S. Minor Outlying Islands",
  }
  let State = data[acronym];
  if (State === undefined) return acronym; else return State;
}

function getCookieDonor(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie != '') {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim(); // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) == name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function getDonorFormData(iframe) {
  const request = new XMLHttpRequest();
  const url = '/api/v2/donor/';
  request.open("GET", url);
  request.setRequestHeader("Content-Type", "application/json");
  request.setRequestHeader("X-CSRFToken", getCookieDonor('csrftoken'));
  request.send();
  request.onload = (e) => {
    if (request.status === 200) {
      let data = JSON.parse(request.response);
      data['address']['state'] = acronymToFullName(data['address']['state']);
      let pkg = {
        USER: data,
      }
      iframe.contentWindow.postMessage(pkg, '*');
    }
  }
}

function getDonorAmount(iframe, amount) {
  let pkg = {
    AMOUNT: amount,
  }
  iframe.contentWindow.postMessage(pkg, '*');
}

// donation template auto-fill
if (donateIframe) {
  donateIframe.addEventListener("load", function () {
    getDonorFormData(donateIframe);
  });
}
