import dialogPolyfill from 'dialog-polyfill';

const cancellationModalTrigger = document.querySelector(".js-launch-cancellation-modal");
const cancellationModal = document.querySelector(".js-cancellation-modal");

if (cancellationModal && cancellationModalTrigger) {
  // polyfills
  dialogPolyfill.registerDialog(cancellationModal);

  // opening the modal
  cancellationModalTrigger.addEventListener("click", () => {
    cancellationModal.showModal();
    // prevent background scroll
    document.querySelector("body").classList.add("modal-open");
    document.querySelector("html").classList.add("modal-open");
  });

  // closing the modal
  const onClose = () => {
    document.querySelector("body").classList.remove("modal-open");
    document.querySelector("html").classList.remove("modal-open");
    // for accessibility: return focus to the trigger button
    // cancellationModalTrigger.focus();
  }

  const closeButtons = document.querySelectorAll(".js-cancellation-modal-close");
  Array.from(closeButtons).forEach((button) => {
    button.addEventListener("click", () => {
      if (cancellationModal.open) {
        cancellationModal.close();
      }
    });
  });
  cancellationModal.addEventListener("close", onClose);

  // close and clear form
  const keepShoppingButtons = document.querySelectorAll(".js-cancellation-modal-refresh");
  if (keepShoppingButtons) {
    Array.from(keepShoppingButtons).forEach((button) => {
      button.addEventListener("click", () => {
        if (cancellationModal.open) {
          cancellationModal.close();
        }
        window.location.reload();
      });
    });
  }
}

const agreeCancelPolicyCheckbox = document.getElementById('checkbox-cancel-policy');
if (agreeCancelPolicyCheckbox) {
  agreeCancelPolicyCheckbox.addEventListener('change', event => {
    const payNowButton = document.querySelector(".js-pay-now-button");
    payNowButton.disabled = !event.target.checked;
  });
}

const payNowForm = document.getElementById("pay-now-form");
if (payNowForm) {
  payNowForm.addEventListener("submit", event => {
    // disable the pay now button
    const payNowButton = document.querySelector(".js-pay-now-button");
    payNowButton.disabled = !event.target.checked;
  });
}
