// NOT CURRENTLY NEEDED SINCE WE TURNED TABS INTO REAL MENU LINKS
// tab logic for Account views
// const accountTabs = document.querySelectorAll(".account__menu-item");
// const accountViews = document.querySelectorAll(".account__section-primary");
// if (accountTabs) {
//   accountTabs.forEach(tab => {
//
//     tab.addEventListener("click", () => {
//       // reassign active labels
//       accountTabs.forEach(el => {
//         el.classList.remove("active");
//         el.setAttribute("aria-selected", "false");
//       });
//       tab.classList.add("active");
//       tab.setAttribute("aria-selected", "true");
//
//       // reassign active content
//       const relatedView = document.querySelector("[aria-labelledby='" + tab.id + "']");
//       if (relatedView) {
//         accountViews.forEach(view => {
//           view.classList.remove("active");
//           view.setAttribute("aria-hidden", "true");
//         });
//         relatedView.classList.add("active");
//         relatedView.setAttribute("aria-hidden", "false");
//       }
//     });
//
//     // apply click event if enter is pressed while tab has keyboard focus
//     tab.addEventListener("keyup", (e) => {
//       if (e.keyCode === 13) {
//         tab.click();
//       }
//     });
//   });
// }

// show/hide order details from Order History tab items
const orderToggles = document.querySelectorAll(".account__details-toggle");
if (orderToggles) {
  Array.from(orderToggles).forEach(toggle => {
    const orderContainer = toggle.nextElementSibling;
    if (orderContainer) {
      const toggleDetails = (e) => {
        e.preventDefault();
        toggle.classList.toggle('expanded');
        const pressed = toggle.getAttribute("aria-pressed") === "true";
        toggle.setAttribute("aria-pressed", !pressed);
        orderContainer.classList.toggle('expanded');

        if (toggle.classList.contains('expanded')) {
          toggle.querySelector("p").innerHTML = "Hide Details";
          toggle.querySelector("svg").style.transform = "rotate(180deg)";
        } else {
          toggle.querySelector("p").innerHTML = "Show Details";
          toggle.querySelector("svg").style.transform = "none";
        }
      }

      toggle.addEventListener("click", toggleDetails);
    }
  })
}
