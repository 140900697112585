// This script randomizes the wax-seal background shape from 12 SVG shapes

// For inline SVGs (framing icons)
const shapes = document.querySelectorAll(".js-randomize-shape");
if (shapes) {
  Array.from(shapes).forEach((shape) => {
    const randomVal = Math.floor(Math.random() * 12) + 1; // random number between 1 and 12
    shape.querySelector("use").setAttribute("xlink:href", "#seal-" + randomVal + "");
  });
}

// For css image mask SVGs (framing photos)
const frameShapes = document.querySelectorAll(".js-randomize-mask-shape");
if (frameShapes) {
  Array.from(frameShapes).forEach((frame) => {
    const randomVal = Math.floor(Math.random() * 12) + 1; // random number between 1 and 12
    frame.classList.forEach(className => {
      if (className.startsWith('seal-')) {
        frame.classList.remove(className);
      }
    });
    frame.classList.add(`seal-${randomVal}`);
  });
}
