import axios from 'axios';

const navCartIcons = document.querySelectorAll('.cart-badge');
const accountFulfillableDots = document.querySelectorAll('.account__menu-notification');
const navFulfillableIcons = document.querySelectorAll('.account__icon-notification');
const navFulfillableDots = document.querySelectorAll('.nav__account-notification');

if (navCartIcons || (navFulfillableIcons && navFulfillableDots) || accountFulfillableDots) {
  axios.get('/api/v2/cart/count/')
    .then(response => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .then(data => {
      const count = data.count;
      const canFulfillToday = data.can_fulfill_today;

      if (navCartIcons && count > 0) {
        Array.from(navCartIcons).forEach((icon) => {
          const counter = icon.querySelector('.cart-badge__count');
          counter.innerHTML = count;
          counter.classList.add('visible');
        });
      }

      // Fulfillable ticket boolean is also returned from cart count API
      // notifications in the nav
      if (navFulfillableIcons && navFulfillableDots && canFulfillToday) {
        Array.from(navFulfillableIcons).forEach(icon => {
          icon.classList.add('visible');
        });
        Array.from(navFulfillableDots).forEach(dot => {
          dot.classList.add('visible');
        });
      }

      // Fulfillable ticket boolean is also returned from cart count API
      // notifications on account view tabs
      if (canFulfillToday && accountFulfillableDots) {
        Array.from(accountFulfillableDots).forEach(dot => {
          dot.classList.add('visible');
        });
      }
    });
}
