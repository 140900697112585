const tabs = document.querySelectorAll(".js-tabs");

if (tabs) {
  // keep scope of JS within each tabs container
  Array.from(tabs).forEach((tabsBlock) => {
    const tabLabels = tabsBlock.querySelectorAll(".tab__label");
    const tabContent = tabsBlock.querySelectorAll(".tab__content");

    let selectedIndex = 0;  // default to first tab

    const hash = window.location.hash;
    if (hash) {
      const hashes = Array.from(tabLabels).map(label => `#${label.id}`);
      const hashIndex = hashes.indexOf(hash);
      if (hashIndex >= 0 && hashIndex < tabLabels.length) {
        selectedIndex = hashIndex;
        // manually find and scroll to the small screen selected tab (desktop is automatic)
        if (tabsBlock.classList.contains("tabs--small") && tabsBlock.offsetParent != null) {
          const targetTab = tabLabels[selectedIndex];
          targetTab.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    if (tabLabels && tabContent) {
      // show selected tab by default
      tabLabels[selectedIndex].classList.add("active");
      tabLabels[selectedIndex].setAttribute("aria-selected", "true");
      tabContent[selectedIndex].classList.add("active");
      tabContent[selectedIndex].setAttribute("aria-hidden", "false");

      // target clicks on each tab label
      Array.from(tabLabels).forEach((label) => {
        // small screen tab clicks should do double duty:
        // click to show and also click to hide on the same tab
        const smallScreenTab = label.classList.contains("label--small");

        const navigateTabs = () => {
          // re-assign active vs. inactive labels
          Array.from(tabLabels).forEach(el => {
            if (!smallScreenTab) {
              el.classList.remove("active");
              el.setAttribute("aria-selected", "false");
            }
          });

          // if it's a large tab, open it
          if (!smallScreenTab) {
            label.classList.add("active");
            label.setAttribute("aria-selected", "true");
          } else {
            // if it's a small tab, open/close based on "active" class
            if (label.classList.contains("active")) {
              label.classList.remove("active");
              label.setAttribute("aria-selected", "false");
            } else {
              Array.from(tabLabels).forEach(el => {
                  el.classList.remove("active");
                  el.setAttribute("aria-selected", "false");
              });
              label.classList.add("active");
              label.setAttribute("aria-selected", "true");
            }
          }

          // re-assign active vs. inactive content
          const content = tabsBlock.querySelector("[aria-labelledby='" + label.id + "']");
          if (content) {
            Array.from(tabContent).forEach(el => {
              if (!smallScreenTab) {
                el.classList.remove("active");
                el.setAttribute("aria-hidden", "true");
              }
            });

            // if it's a large tab, open it
            if (!smallScreenTab) {
              content.classList.add("active");
              content.setAttribute("aria-hidden", "false");
            } else {
              // if it's a small tab, open/close based on "active" class
              if (content.classList.contains("active")) {
                content.classList.remove("active");
                content.setAttribute("aria-hidden", "true");
              } else {
                Array.from(tabContent).forEach(el => {
                  el.classList.remove("active");
                  el.setAttribute("aria-hidden", "true");
                });
                content.classList.add("active");
                content.setAttribute("aria-hidden", "false");
              }
            }
          }
        }

        // capture tab nav with click and keyboard nav
        label.addEventListener("click", navigateTabs);
        label.addEventListener("keypress", (e) => {
          if (e.key === "Enter" || e.which === 13) {
            navigateTabs();
          }
        });
      });
    }
  });
}
