// Google Analytics JS
function trackNavEvent(e) {
  const target = e.target.closest(".nav__link").name;
  ga("send", "event", {
    eventCategory: "page nav",
    eventAction: "click",
    eventLabel: target,
    transport: "beacon"
  });
}

function trackHeaderEvent(e) {
  ga("send", "event", {
    eventCategory: "header button",
    eventAction: "click",
    eventLabel: e.target.name,
    transport: "beacon"
  });
}

const navLinks = [...document.getElementsByClassName("nav__link")];
navLinks.forEach(l => {
  l.addEventListener("click", e => {
    trackNavEvent(e);
  });
});

const headerButtons = [...document.getElementsByClassName("header__button")];
headerButtons.forEach(l => {
  l.addEventListener("click", e => {
    trackHeaderEvent(e);
  });
});
