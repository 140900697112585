const modalButtons = document.getElementsByClassName('modal-btn');
let activeModal = null;

Array.from(modalButtons).forEach((modalButton) => {
  const ticketModalId = modalButton.dataset.ticket;
  const modal = document.getElementById(ticketModalId);
  modalButton.addEventListener('click', (event) => {
    activeModal = modal;
    modal.style.display = 'block';
    // prevent background scroll
    document.querySelector("body").classList.add("modal-open");
    document.querySelector("html").classList.add("modal-open");
  });
  const closeButtons = modal.querySelectorAll('.close-btn');
  Array.from(closeButtons).forEach((closeButton) => {
    closeButton.addEventListener('click', () => {
      modal.style.display = 'none';
      activeModal = null;
      document.querySelector("body").classList.remove("modal-open");
      document.querySelector("html").classList.remove("modal-open");
    });
  });
});

window.onclick = (event) => {
  if (activeModal && event.target == activeModal) {
    document.querySelector("body").classList.remove("modal-open");
    document.querySelector("html").classList.remove("modal-open");
    activeModal.style.display = 'none';
    activeModal = null;
  }
};

document.onkeydown = (event) => {
  if (activeModal) {
    event = event || window.event;
    let isEscape = false;
    if ('key' in event) {
      isEscape = event.key === 'Escape' || event.key === 'Esc';
    } else {
      isEscape = event.keyCode === 27;
    }
    if (isEscape) {
      document.querySelector("body").classList.remove("modal-open");
      document.querySelector("html").classList.remove("modal-open");
      activeModal.style.display = 'none';
      activeModal = null;
    }
  }
};
