const searches = document.querySelectorAll('.js-search');
if (searches) {
  Array.from(searches).forEach(search => {
    const input = search.querySelector('input');
    const close = search.querySelector('.js-search-close');

    input.addEventListener('focus', function() {
      search.classList.add('open');
    });

    input.addEventListener('blur', function() {
      search.classList.remove('open');
    });

    close.addEventListener('click', function() {
      search.classList.remove('open');
    });
  });
}
