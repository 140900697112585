import Glide from '@glidejs/glide';

const carouselContainer = document.querySelector(".glide.carousel");
if (carouselContainer) {
  const carousel = new Glide(".glide.carousel", {
    type: 'carousel',
    autoplay: 0,
    gap: 0,
  });
  // if autoplay is on, use the following to remove hoverpause behavior and allow click-button-to-pause functionality
  // hoverpause: false,

  // if autoplay is on, the below functionality is needed for a pause button
  // const pauseButton = document.querySelector(".glide__pause");
  // if (pauseButton) {
  //   pauseButton.addEventListener("click", () => {
  //     if (pauseButton.classList.contains("paused")) {
  //       carousel.play();
  //       console.log('resumed autoplay');
  //       pauseButton.classList.remove("paused");
  //     } else {
  //       carousel.pause();
  //       console.log('paused autoplay');
  //       pauseButton.classList.add("paused");
  //     }
  //   });
  // }

  // get crafty without disturbing natural tab order for keyboard nav
  // if a slide's tabbable content has focus, and the slide is not already designated as active, then make the slide active (aka follow the tab focus)
  const followFocus = () => {
    const allSlides = carouselContainer.querySelectorAll(".glide__slide");
    Array.from(allSlides).forEach(slide => {
      const slideArrowLeft = slide.querySelector(".prev");
      const slideArrowRight = slide.querySelector(".next");
      const slideButton = slide.querySelector("a");
      if (!slide.classList.contains("glide__slide--active")) {
        if (document.activeElement === slideArrowLeft || document.activeElement === slideArrowRight || document.activeElement === slideButton) {
          allSlides.forEach(el => {
            el.classList.remove("glide__slide--active");
          });
          slide.classList.add("glide__slide--active");
        }
      }
    });
  }

  document.addEventListener("keyup", (e) => {
    if (e.key === "Tab" || e.which === 9) {
      followFocus();
    }
  });

  // Called right before calculating new index and making a transition. The movement schema (eg. =1) string has been parsed.
  carousel.on('run.before', (movement) => {
    // if moving to the curent index, do nothing
    // purposefully using == for comparing index as movement.steps 0 is a string for some reason while 1+ is numbers
    if (movement.direction === '=' && movement.steps == carousel.index) {
      return;
    }

    const activeSlide = document.querySelector(".glide__slide--active");
    const activeContent = activeSlide.querySelector(".glide__slide-content__container");
    const allContent = document.querySelectorAll(".glide__slide-content__container");
    Array.from(allContent).forEach((el) => {
      el.classList.remove("slide-transition");
    });
    activeContent.classList.add("slide-transition");

    const activeBg = activeSlide.querySelector(".glide__slide-content");
    const allBgs = document.querySelectorAll(".glide__slide-content");
    Array.from(allBgs).forEach((el) => {
      el.classList.remove("slide-transition");
    });
    setTimeout(() => {
      activeBg.classList.add("slide-transition");
    }, 200);
  });

  carousel.mount();
}
