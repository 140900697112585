// target first word of paragraph
const introParagraphs = document.querySelectorAll(".js-dropcap-word");
if (introParagraphs) {
  introParagraphs.forEach(introParagraph => {
    const firstParagraph = introParagraph.querySelector("p");
    let words = firstParagraph.innerHTML;
    const firstword = words.split(" ")[0];
    words = words.substr(words.indexOf(" ") + 1);
    firstParagraph.innerHTML = `<span class="word-dropcap">${firstword} </span>${words}`;
  });
}

// story dropcap
const storySections = document.querySelectorAll(".story-section");
if (storySections) {
  storySections.forEach(story => {
    const firstParagraph = Array.from(story.querySelectorAll(".story-block--paragraph"))[0];
    const num = Math.floor(Math.random() * 6) + 1; // random number between 1 and 7
    firstParagraph.classList.add(`frame`);
    firstParagraph.classList.add(`frame-${num}`);
  });
}
