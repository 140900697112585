import Glide from '@glidejs/glide';

const articleSliders = document.querySelectorAll(".glide.articles");
if (articleSliders) {
  Array.from(articleSliders).forEach((el) => {
    const slider = new Glide(el, {
      type: 'slider',
      gap: 2,
      perView: 1,
      peek: {
        before: 0,
        after: 15,
      },
    });
    slider.mount();
  });
}

// dynamic height calculation for article cards grid
const articleGrids = document.querySelectorAll(".article__grid");
if (articleGrids) {
  Array.from(articleGrids).forEach((grid) => {
    const gridItems = grid.querySelectorAll(".article__grid-item");
    if (gridItems.length == 3) {
      gridItems[0].style.gridRowEnd = "span 2";
    }
  });
}
