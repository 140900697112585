// Apply opaque styles to transparent nav on scroll
const nav = document.querySelector(".nav--transparent-light") || document.querySelector(".nav--transparent");
const body = document.querySelector("body");
if (nav && body) {
  const frozenBody = body.classList.contains("menu-open");
  window.onscroll = () => {
    if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 60) {
      nav.classList.add("opaque");
    } else {
      // only remove class if the nav is closed
      if (!frozenBody) {
        nav.classList.remove("opaque");
      }
    }
  }
}
