class Lazyload {
  constructor(elem) {
    this.elem = elem;
    this.intersectionObserver = null;
    this.init = this.init.bind(this);
    this.initIntersectionObserver = this.initIntersectionObserver.bind(this);
    this.init();
  }

  init() {
    this.initIntersectionObserver();
  }

  initIntersectionObserver() {
    const observer = new IntersectionObserver(this.handleIntersection);
    if (this.elem) {
      observer.observe(this.elem);
    }
  }

  handleIntersection(entries) {
    if (entries[0] && entries[0].isIntersecting) {
      const { target } = entries[0];
      target.setAttribute("src", target.getAttribute("data-src"));
      target.classList.add("lazyloaded");
    }
  }
}

const lazyloaders = document.querySelectorAll("[data-lazyload]");

if (lazyloaders) {
  Array.from(lazyloaders).forEach(lazyloader => new Lazyload(lazyloader));
}
