const donationFrequency = document.querySelector('.donate-widget__options');
if (donationFrequency) {
  const oneTimeInput = donationFrequency.querySelector('.js-donate-once');
  const monthlyInput = donationFrequency.querySelector('.js-donate-monthly');
  const monthlyDono = document.querySelector('#mo');

  const evaluateInput = () => {
    if (monthlyInput.checked) {
      monthlyDono.innerHTML = 'USD/month';
    } else {
      monthlyDono.innerHTML = 'USD';
    }
  };

  oneTimeInput.addEventListener('change', evaluateInput);
  monthlyInput.addEventListener('change', evaluateInput);
  evaluateInput();
}
