// Membership Index toggle
const toggleOnce = document.querySelector(".js-mem-once");
const toggleMonthly = document.querySelector(".js-mem-monthly");

if (toggleOnce && toggleMonthly) {
  const membershipLevels = document.querySelectorAll(".js-membership-amount");
  const membershipLinks = document.querySelectorAll(".js-membership-link");
  const toggleAmounts = () => {
    if (membershipLevels && membershipLinks) {
      Array.from(membershipLevels).forEach((level) => {
        const annualDisplay = level.querySelector(".selected-once");
        const monthlyDisplay = level.querySelector(".selected-monthly");
        if (toggleOnce.checked) {
          annualDisplay.classList.remove("hidden");
          monthlyDisplay.classList.add("hidden");
        } else {
          annualDisplay.classList.add("hidden");
          monthlyDisplay.classList.remove("hidden");
        }
      });
      Array.from(membershipLinks).forEach((link) => {
        if (toggleOnce.checked) {
          if (link.classList.contains("monthly")) {
            link.style.display = "none";
          } else {
            link.style.display = "flex";
          }
        } else {
          if (link.classList.contains("monthly")) {
            link.style.display = "flex";
          } else {
            link.style.display = "none";
          }
        }
      });
    }
  }
  toggleOnce.addEventListener("change", toggleAmounts);
  toggleMonthly.addEventListener("change", toggleAmounts);
}

// Membership Detail toggle (from index page)
const benefitsToggle = document.querySelector(".js-toggle-benefits");
const benefits = document.querySelector(".membership-benefits__list");
if (benefitsToggle && benefits) {
  const icon = benefitsToggle.querySelector("svg");
  const title = benefitsToggle.querySelector("span");

  benefitsToggle.addEventListener("click", () => {
    if (benefitsToggle.getAttribute("aria-pressed") == "false") {
      benefitsToggle.setAttribute("aria-pressed", "true");
      benefits.style.maxHeight = "1000px";
      icon.style.transform = "none";
      title.innerHTML = "Hide Advantages";
    } else {
      benefitsToggle.setAttribute("aria-pressed", "false");
      benefits.style.maxHeight = 0;
      icon.style.transform = "rotate(180deg)";
      title.innerHTML = "View Advantages";
    }
  });
}

// Membership Detail toggle (from detail page)
const monthlyAnchor = location.hash;
const membershipWrappers = document.querySelector(".membership-forms");

if (membershipWrappers) {
  // wait for abila content to fully load
  const intervalForRadios = setInterval(() => {
    if (membershipWrappers.querySelector(".kimbiaDiv")) {
      // stop checking
      clearInterval(intervalForRadios);

      const onetimeWrapper = membershipWrappers.querySelector("#membership-form-onetime");
      const monthlyWrapper = membershipWrappers.querySelector("#membership-form-monthly");
      if (onetimeWrapper && monthlyWrapper) {
        const onetimeButton = document.querySelector(".js-abila-mem-once");
        const monthlyButton = document.querySelector(".js-abila-mem-monthly");
        const onetimeLabel = document.querySelector(".left-label");
        const monthlyLabel = document.querySelector(".right-label");

        // if URL anchor is monthly, show the correct form + radio check
        if (location.hash == "#monthly") {
          monthlyButton.checked = true;
          onetimeWrapper.classList.add("hidden");
          onetimeWrapper.setAttribute("aria-hidden", "true");
          monthlyWrapper.classList.remove("hidden");
          monthlyWrapper.setAttribute("aria-hidden", "false");
        }

        const toggleMembershipsOneTime = () => {
            onetimeWrapper.classList.remove("hidden");
            onetimeWrapper.setAttribute("aria-hidden", "false");
            monthlyWrapper.classList.add("hidden");
            monthlyWrapper.setAttribute("aria-hidden", "true");
        };

        const toggleMembershipsMonthly = () => {
            onetimeWrapper.classList.add("hidden");
            onetimeWrapper.setAttribute("aria-hidden", "true");
            monthlyWrapper.classList.remove("hidden");
            monthlyWrapper.setAttribute("aria-hidden", "false");
        };



        // if button is toggled, show correct form
        onetimeLabel.addEventListener("click", toggleMembershipsOneTime);
        monthlyLabel.addEventListener("click", toggleMembershipsMonthly);
      }
    }
  }, 100);
}
