// This script randomizes the background color from a preset array

const fillShapes = document.querySelectorAll('.js-randomize-color');
if (fillShapes) {
  const colors = [
    '#264461', // $blue
    '#4d5944', // $green
    '#ebb799', // $peach
    '#e3c268', // $mustard
    '#b9af77', // $chartreuse
    '#a3ccbd', // $robinsegg
    '#3072b1', // $blueLInk
  ];
  Array.from(fillShapes).forEach((shape) => {
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    shape.style.backgroundColor = randomColor;
  });
}
