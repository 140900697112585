const webcamImage = document.querySelector(".js-webcam-image");

if (webcamImage) {
  const autoRefreshTime = 5000;
  const slideshowTime = 500;

  function reloadImage() {
    const d = new Date();
  	const t = d.getTime();
    const newSrc = webcamImage.getAttribute('data-src') + '?' + t;
    webcamImage.src = newSrc;
  }

  function nextImage() {
    if (imageIndex >= WEBCAM_IMAGES.length) {
      imageIndex = 0;
    }
    const imageSrc = WEBCAM_IMAGES[imageIndex];
    webcamImage.src = imageSrc;
    imageIndex += 1;
  }

  let autoRefreshInterval = setInterval(reloadImage, autoRefreshTime);
  let slideshowInterval;
  let imageIndex = 0;
  const currentImageButton = document.querySelector(".js-webcam-current");
  const last24HoursButton = document.querySelector(".js-webcam-last-24");
  last24HoursButton.addEventListener("click", event => {
    last24HoursButton.style.display = 'none';
    currentImageButton.style.display = 'flex';
    clearInterval(autoRefreshInterval);
    slideshowInterval = setInterval(nextImage, slideshowTime);
  });

  currentImageButton.addEventListener("click", event => {
    currentImageButton.style.display = 'none';
    last24HoursButton.style.display = 'flex';
    clearInterval(slideshowInterval);
    reloadImage();
    autoRefreshInterval = setInterval(reloadImage, autoRefreshTime);
  });
}
