const submitButtonForms = document.getElementsByClassName('js-submit-button-form');

if (submitButtonForms) {
  Array.from(submitButtonForms).forEach(form => {
    form.addEventListener('submit', event => {
      const submitButtons = form.querySelectorAll('button[type=submit]');
      Array.from(submitButtons).forEach(submitButton => {
        submitButton.disabled = true;
        if (submitButton.dataset.submitlabel) {
          submitButton.innerText = submitButton.dataset.submitlabel;
        }
      });
    });
  });
}
