import dialogPolyfill from 'dialog-polyfill';
import Glide from '@glidejs/glide';

const imageBlocks = document.querySelectorAll(".story-block--image");
const sections = document.querySelectorAll(".story-section");

// image modal
if (imageBlocks) {
  imageBlocks.forEach(imageBlock => {
    const modalTrigger = imageBlock.querySelector(".js-image-modal-trigger");
    const modalImage = imageBlock.querySelector(".story-block--image__wrapper");
    const modal = imageBlock.querySelector("dialog");
    if (modalTrigger && modal) {
      // polyfills
      dialogPolyfill.registerDialog(modal);

      // apply cursor to image
      modalImage.style.cursor = "pointer";

      const launchImageModal = () => {
        modal.showModal();
        // prevent background scroll
        document.querySelector("body").classList.add("modal-open");
        document.querySelector("html").classList.add("modal-open");
      };

      const extendHover = () => {
        modalImage.classList.add("hover");
        modalTrigger.classList.add("hover");
      }

      const removeHover = () => {
        modalImage.classList.remove("hover");
        modalTrigger.classList.remove("hover");
      }
      // shared hover effect
      modalImage.addEventListener('mouseover', extendHover);
      modalTrigger.addEventListener('mouseover', extendHover);
      modalImage.addEventListener('mouseout', removeHover);
      modalTrigger.addEventListener('mouseout', removeHover);
      // listen for click / space / enter
      modalImage.addEventListener('click', launchImageModal);
      modalTrigger.addEventListener('click', launchImageModal);
      modalTrigger.addEventListener("keypress", (e) => {
        if (e.key === "Enter" || e.which === 13 || e.key === " " || e.which === 32) {
          e.preventDefault();
          launchImageModal();
        }
      });

      const closeButton = modal.querySelector(".js-image-modal-close");
      closeButton.addEventListener("click", () => {
        if (modal.open) {
          modal.close();
        }
      });

      const onClose = () => {
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector("html").classList.remove("modal-open");
        // for accessibility: return focus to the trigger button
        modalTrigger.focus();
      };

      modal.addEventListener("close", onClose);
    }
  });
}

// happenings modal
if (sections) {
  sections.forEach(section => {
    const modalTrigger = section.querySelector(".story-section__sidebar--right");
    const modal = modalTrigger ? modalTrigger.nextElementSibling : null;

    if (modalTrigger && modal && modal.localName === 'dialog') {
      // polyfills
      dialogPolyfill.registerDialog(modal);

      const sectionModal = section.querySelector(".glide.happenings");
      const carousel = new Glide(sectionModal, {
        type: 'carousel',
        // set to 0 to remove autoplay
        autoplay: 10000, // 10 seconds per slide
        // if autoplay is on, use the following to remove hoverpause behavior and allow click-button-to-pause functionality
        hoverpause: false,
        gap: 0,
        perView: 1,
        // disallow swiping/dragging
        swipeThreshold: false,
        dragThreshold: false,
        animationDuration: 600,
        animationTimingFunc: 'ease-in',
      });
      const allSlides = section.querySelectorAll('.glide__slide');

      // slide counter
      const slideTotal = allSlides.length;
      const currentSlide = section.querySelector(".js-count-current");
      section.querySelector(".js-count-total").innerHTML = slideTotal;
      carousel.on('run.after', () => {
        const activeSlide = section.querySelector(".glide__slide--active");
        if (activeSlide) {
          currentSlide.innerHTML = parseInt(activeSlide.getAttribute('data-count')) + 1;
        }
      });

      // force all slides to take on full height of largest slide
      const evaluateSliderHeight = () => {
        const glideTrackHeight = section.querySelector('.glide__track').offsetHeight;
        allSlides.forEach(slide => {
          slide.style.height = `${glideTrackHeight}px`;
        });
      };
      carousel.on('build.after', evaluateSliderHeight);

      // if autoplay is on, the below functionality is needed for a pause button
      const pauseButton = section.querySelector(".story-modal__controls__counter");
      if (pauseButton) {
        const pauseIcon = pauseButton.querySelector(".story-modal__pause");
        const playIcon = pauseButton.querySelector(".story-modal__play");
        const span = pauseButton.querySelector(".story-modal__count");
        const path = pauseButton.querySelector(".story-modal__stamp-outline--dark");
        const showIcon = () => {
          span.classList.add("hide");
          if (pauseButton.classList.contains("paused")) {
            playIcon.classList.add("show");
          } else {
            pauseIcon.classList.add("show");
          }
        };
        const hideIcon = () => {
          span.classList.remove("hide");
          playIcon.classList.remove("show");
          pauseIcon.classList.remove("show");
        };
        // PRV and NXT or arrow nav should cause animation to restart
        const restartAnimation = () => {
          path.classList.remove("js-animate");
          path.getBoundingClientRect(); // force reflow
          path.classList.remove("fade");
          path.classList.add("js-animate");
          path.style.animationPlayState = "running";
          pauseButton.classList.remove("paused");
        };
        carousel.on('move', restartAnimation);
        pauseButton.addEventListener("mouseover", showIcon);
        pauseButton.addEventListener("mouseout", hideIcon);
        pauseButton.addEventListener("click", () => {
          if (pauseButton.classList.contains("paused")) {
            carousel.play();
            pauseButton.classList.remove("paused");
            // Glide JS does not "pause" it restarts full interval, so animation needs to do the same
            restartAnimation();
          } else {
            carousel.pause();
            pauseButton.classList.add("paused");
            path.style.animationPlayState = "paused";
            path.classList.add("fade"); // fade off path so the animation restart isn't jarring
          }
        });
      }

      const launchHappeningsModal = () => {
        modal.showModal();
        // prevent background scroll
        document.querySelector("body").classList.add("modal-open");
        document.querySelector("html").classList.add("modal-open");
        // mount carousel only if modal has been opened, otherwise dimension calculations don't work
        // if it has already been mounted (user opens modal, closes, opens again) then restart at 0
        const mountedCarousel = modal.querySelector(".glide--carousel");
        if (mountedCarousel) {
          carousel.update({ startAt: 0 });
          currentSlide.innerHTML = 1;
        } else {
          carousel.mount();
        }
      };
      // listen for click / space / enter
      modalTrigger.addEventListener('click', launchHappeningsModal);
      modalTrigger.addEventListener('keypress', (e) => {
        if (e.key === "Enter" || e.which === 13 || e.key === " " || e.which === 32) {
          e.preventDefault();
          launchHappeningsModal();
        }
      });

      const closeButton = modal.querySelector(".js-happenings-modal-close");
      closeButton.addEventListener("click", () => {
        if (modal.open) {
          modal.close();
        }
      });

      const onClose = () => {
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector("html").classList.remove("modal-open");
        // for accessibility: return focus to the trigger button
        modalTrigger.focus();
      };

      modal.addEventListener("close", onClose);
    }
  });
}
