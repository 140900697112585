// menu toggle for user icon -> account options dropdown in the nav

const accountToggles = document.querySelectorAll(".nav__sign-in");
if (accountToggles) {
  Array.from(accountToggles).forEach(accountToggle => {

    const toggleMenu = () => {
      const dropdown = accountToggle.nextElementSibling;
      dropdown.classList.toggle("expanded");
    }

    // listen for click, enter key, or space key
    accountToggle.addEventListener("click", toggleMenu);
    accountToggle.addEventListener("keypress", (e) => {
      if (e.key === "Enter" || e.which === 13 || e.key === " " || e.which === 32) {
        e.preventDefault();
        toggleMenu();
      }
    });
  });
}
