const messageBar = document.querySelector(".js-visit-bar");

if (messageBar) {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();
  const openTime = messageBar.dataset.openTime.split(":");
  const closeTime = messageBar.dataset.closeTime.split(":");
  const todayOpening = new Date(year, month, day, parseInt(openTime[0]), parseInt(openTime[1]));
  const todayClosing = new Date(year, month, day, parseInt(closeTime[0]), parseInt(closeTime[1]));
  if (todayOpening <= now && now <= todayClosing) {
    messageBar.querySelector(".header__visit-bar__message--primary").innerHTML = messageBar.dataset.openPrimary;
    messageBar.querySelector(".header__visit-bar__message--secondary").innerHTML = messageBar.dataset.openSecondary;
  }
}
