// static maps on location and event detail pages
const mapBlocks = document.querySelectorAll('.js-map');

if (mapBlocks) {
  // give initMap a global scope
  window.initMaps = function initMap() {
    const styledMapType = new google.maps.StyledMapType(
      [
        {
          featureType: "administrative",
          elementType: "labels",
          stylers: [{
              visibility: "off"
          }]
        }, {
          featureType: "poi",
          elementType: "labels",
          stylers: [{
              visibility: "off"
          }]
        }, {
          featureType: "water",
          elementType: "labels",
          stylers: [{
              visibility: "off"
          }]
        }, {
          featureType: "road",
          elementType: "labels",
          stylers: [{
              visibility: "on"
          }]
        }
      ],
      {
        name: "Map"
      }
    );

    for(const mapBlock of mapBlocks) {
      const blockLat = parseFloat(mapBlock.dataset.lat);
      const blockLon = parseFloat(mapBlock.dataset.lon);
      const location = {lat: blockLat, lng: blockLon};
      const shiftedY = blockLat + 0.0003;

      const map = new google.maps.Map(mapBlock, {
        center: {lat: shiftedY, lng: blockLon},
        zoom: 17,
        minZoom: 17,
        maxZoom: 17,
        gestureHandling: 'none',
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControlOptions: {
          mapTypeIds: ["custom_map"],
          position: google.maps.ControlPosition.LEFT_BOTTOM
        }
      });

      // Associate the styled map with the MapTypeId and set it to display.
      map.mapTypes.set('custom_map', styledMapType);
      map.setMapTypeId('custom_map');

      const pin = '/static/images/pin.png';
      const marker = new google.maps.Marker({
        position: location,
        map: map,
        icon: pin
      });
    }
  }
}
