// Calendar datepicker is using Flatpickr library

// override with custom arrows
// SVGs: chevron-back, chevron-next
flatpickr.defaultConfig.nextArrow =
  '<svg width="12" height="20" viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg"><title>Next</title><path d="M8.599 10.526L9.407 10 8.6 9.473C3.946 6.532.178.857 0 .586L.834 0c.048.072 4.839 7.29 10.042 9.518L12 10l-1.124.481C5.673 12.709.882 19.927.834 20L0 19.414c.178-.272 3.945-5.947 8.599-8.888" fill="currentColor" fill-rule="evenodd"/></svg>';
flatpickr.defaultConfig.prevArrow =
  '<svg width="12" height="20" viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg"><title>Previous</title><path d="M3.401 10.526L2.593 10l.807-.527C8.054 6.532 11.822.857 12 .586L11.166 0c-.048.072-4.839 7.29-10.042 9.518L0 10l1.124.481c5.203 2.228 9.994 9.446 10.042 9.519l.834-.586c-.178-.272-3.945-5.947-8.599-8.888" fill="currentColor" fill-rule="evenodd"/></svg>';

// standard datepicker form
const dateForm = document.querySelector('.js-contains-datepicker');

if (dateForm) {
  const cal = dateForm.querySelector('.datepicker');
  // get default display date (first occurrence)
  let calDefault = cal.getAttribute('data-default');
  // override default date with param if applicable
  if (location.search) {
    const param = new URLSearchParams(location.search);
    const dateParam = param.get('date');
    if (dateParam) {
      calDefault = dateParam;
    }
  }

  // get available date range
  let calRange = [];
  if (cal.getAttribute('data-range')) {
    calRange = cal.getAttribute('data-range').split(',');
  }
  // get min date
  const calMinDate = cal.getAttribute('data-min-date');
  // get max date
  const calMaxDate = cal.getAttribute('data-max-date');

  // flatpickr instance & configs
  const datepicker = flatpickr('.datepicker', {
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d',
    minDate: calMinDate || 'today',
    maxDate: calMaxDate,
    monthSelectorType: 'static',
    appendTo: document.querySelector('.form-input--datepicker'),
    defaultDate: calDefault,
    enable: calRange,
  });

  // get available times for selected date
  const getAvailableDates = () => {
    const timeSelect = dateForm.querySelector('#select-ticket-time');
    if (timeSelect) {
      const timeOptions = timeSelect.querySelectorAll('option');
      const selectedDate = cal.value;
      // each time date is changed, deselect+hide all time options and only select the first valid one
      Array.from(timeOptions).forEach((el) => {
        el.selected = false;
        el.classList.add('form-input__hidden');
        if (el.value === selectedDate) {
          el.selected = true;
          el.classList.remove('form-input__hidden');
        }
      });
    }
  };

  const decrementDay = document.querySelector('.datepicker__prev-day');
  const incrementDay = document.querySelector('.datepicker__next-day');
  let nextAvailableDate = null;
  let prevAvailableDate = null;
  let upcomingDates = [];
  let previousDates = [];

  // check prev/next availability calendar logic
  if (calRange.length > 0 && decrementDay && incrementDay) {
    const checkAvailability = () => {
      const formattedSelectedDate = new Date(cal.value);
      const formattedAvailableDates = calRange.map((date) => new Date(date));
      nextAvailableDate = null;
      prevAvailableDate = null;
      upcomingDates = [];
      previousDates = [];
      formattedAvailableDates.forEach((date) => {
        if (date.getTime() > formattedSelectedDate.getTime()) {
          upcomingDates.push(date);
        }
        if (date.getTime() < formattedSelectedDate.getTime()) {
          previousDates.push(date);
        }
      });

      // find closest prev date
      if (previousDates.length) {
        decrementDay.disabled = false;
        prevAvailableDate = previousDates.reverse()[0];
      } else {
        decrementDay.disabled = true;
      }

      // find closest next date
      if (upcomingDates.length) {
        incrementDay.disabled = false;
        nextAvailableDate = upcomingDates[0];
      } else {
        incrementDay.disabled = true;
      }
    };

    decrementDay.addEventListener('click', () => {
      if (prevAvailableDate) {
        // 'true' flag triggers onChange hook
        datepicker.setDate(prevAvailableDate.toISOString().slice(0, 10), true);
      }
    });

    incrementDay.addEventListener('click', () => {
      if (nextAvailableDate) {
        // 'true' flag triggers onChange hook
        datepicker.setDate(nextAvailableDate.toISOString().slice(0, 10), true);
      }
    });

    // on initial load:
    getAvailableDates();
    checkAvailability();

    // whenever calendar date is changed:
    datepicker.config.onChange.push(function() {
      getAvailableDates();
      checkAvailability();
    });
  }
}
