const schedulePage = document.querySelector(".daily-schedule-page");
if (schedulePage) {
  let dateParam = null;
  let datepicker;
  let dateClearButton;
  let calIconTrigger;
  let decrementDay;
  let incrementDay;
  let datepickerInputs;

  // set calendar initial state
  initializeCalendar();

  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);

    // set filter button initial state
    updateFilterButton(window.location);

    dateParam = params.get('date');
    const accessible = params.get('accessible');
    const time_of_day = params.get('time_of_day');
    const experience_types = params.get('experiences');
    const interests = params.get('interests');
    const result_type = params.get('type'); // this is the filter for Events vs Sites
    const query = params.get('query');

    // also init date from input field
    const scheduleDate = document.getElementById("schedule-date");
    if (scheduleDate && scheduleDate.value) {
      dateParam = scheduleDate.value;
    }

    if (dateParam) {
      // update flatpickr instance value
      datepicker.setDate(dateParam);
      // show clearDateButton + calendar icon
      dateClearButton.style.display = 'block';
      calIconTrigger.style.display = 'block';
      // remove blank styles
      datepickerInputs.forEach((input) => { input.classList.remove('no-value'); });
      // enable arrows
      decrementDay.disabled = false;
      incrementDay.disabled = false;

      // add date param to event URLs
      const gridItems = document.querySelectorAll('.event__content');
      if (gridItems) {
        gridItems.forEach((el) => {
          let link = el.href;
          link = link + `?date=${dateParam}`;
          el.href = link;
        });
      }

    } else {
      // hide clearDateButton + calendary icon
      dateClearButton.style.display = 'none';
      calIconTrigger.style.display = 'none';
      // add blank styles
      datepickerInputs.forEach((input) => { input.classList.add('no-value'); });
      // disable arrows
      decrementDay.disabled = true;
      incrementDay.disabled = true;
    }

    if (accessible) {
      const value = accessible === 'true' ? true : false;
      document.getElementById('checktoggle').checked = value;
    }

    if (result_type) {
      const toggle = document.querySelector('.daily-schedule-page__event-site-toggle');
      const eventButton = toggle.querySelector('#events');
      const siteButton = toggle.querySelector('#sites');
      const accessibilityToggle = document.querySelector('.filter-menu__accessibility-wrapper');
      const interestFieldset = document.querySelector('.filter-menu__interests');
      if (result_type == eventButton.id) {
        siteButton.classList.remove('selected');
        siteButton.setAttribute('aria-pressed', 'false');
        eventButton.classList.add('selected');
        eventButton.setAttribute('aria-pressed', 'true');
        // hide accessibility toggle for EventsOnly
        accessibilityToggle.classList.remove('reveal');
        accessibilityToggle.setAttribute('aria-hidden', 'true');
        // show interests list for EventsOnly
        interestFieldset.classList.add('reveal');
        interestFieldset.setAttribute('aria-hidden', 'false');
      } else if (result_type == siteButton.id) {
        eventButton.classList.remove('selected');
        eventButton.setAttribute('aria-pressed', 'false');
        siteButton.classList.add('selected');
        siteButton.setAttribute('aria-pressed', 'true');
        // show accessibility toggle for SitesOnly
        accessibilityToggle.classList.add('reveal');
        accessibilityToggle.setAttribute('aria-hidden', 'false');
        // hide interests list for SitesOnly
        interestFieldset.classList.remove('reveal');
        interestFieldset.setAttribute('aria-hidden', 'true');
      }
    }

    if (time_of_day) {
      const timesCheckboxes = document.querySelectorAll('#times-fieldset input[type="checkbox"]');
      const selectedTimeOfDay = time_of_day.split(',');

      if (timesCheckboxes) {
        for (let checkbox of timesCheckboxes) {
          checkbox.checked = (selectedTimeOfDay.indexOf(checkbox.id) >= 0);
        }
      }
    }

    if (experience_types) {
      const experienceTypeCheckboxes = document.querySelectorAll('#experience-type-fieldset input[type="checkbox"]');
      const selectedExperienceTypes = experience_types.split(',');

      if (experienceTypeCheckboxes) {
        for (let checkbox of experienceTypeCheckboxes) {
          checkbox.checked = (selectedExperienceTypes.indexOf(checkbox.name) >= 0);
        }
      }
    }

    if (interests) {
      const interestCheckboxes = document.querySelectorAll('#interests-fieldset input[type="checkbox"]');
      const selectedInterests = interests.split(',');

      if (interestCheckboxes) {
        for (let checkbox of interestCheckboxes) {
          checkbox.checked = (selectedInterests.indexOf(checkbox.name) >= 0);
        }
      }
    }

    if (query) {
      // update input display with search param from URL
      const searchInput = document.querySelector(".js-schedule-search-input");
      searchInput.value = query;
    }
  }

  function openFilterMenu(event) {
    // prevent background scroll
    document.querySelector('body').classList.add('menu-open');
    document.querySelector('html').classList.add('menu-open');
    // slide menu
    const menu = document.querySelector('.daily-schedule-page__filter-menu');
    const closeBtn = menu.querySelector('.js-filter-menu-close');
    menu.classList.add("open");
    menu.style.transform = 'translateX(0)';
    menu.setAttribute('aria-hidden', 'false');

    // close menu
    // listen for click, enter key, or space key
    const closeMenu = () => {
      menu.classList.remove("open");
      menu.style.transform = 'translateX(400px)';
      menu.setAttribute('aria-hidden', 'true');
      document.querySelector('body').classList.remove('menu-open');
      document.querySelector('html').classList.remove('menu-open');
      // return focus to filter toggle
      event.target.focus();
    };

    closeBtn.addEventListener('click', closeMenu);
    closeBtn.addEventListener('keypress', (e) => {
      if (e.key === 'Enter' || e.which === 13 || e.key === ' ' || e.which === 32) {
        e.preventDefault();
        closeMenu();
      }
    });
  }
  const openButton = document.querySelector(".js-open-filter-menu");
  openButton.addEventListener("click", openFilterMenu);

  function updateExperienceTypes(location) {
    /*
    * @param location URL{Object} current window location
    *
    * @returns URL{object}
    */
    const experienceFieldset = document.getElementById('experience-type-fieldset');
    const onlyChecked = experienceFieldset.querySelectorAll('input[type="checkbox"]:checked');
    const checkedIds = Array.from(onlyChecked, (checkbox) => checkbox.getAttribute('name'));

    if (checkedIds.length > 0) {
      const experienceParamValue = checkedIds.join();
      location.searchParams.set('experiences', experienceParamValue);
    } else {
      // if all or none are checked, remove param from url
      location.searchParams.delete('experiences');
    }
    return location;
  }

  function updateInterests(location) {
    /*
    * @param location URL{Object}
    *
    * @returns URL{object}
    */
    const interestFieldset = document.getElementById('interests-fieldset');
    const onlyChecked = interestFieldset.querySelectorAll('input[type="checkbox"]:checked');
    const checkedIds = Array.from(onlyChecked, (checkbox) => checkbox.getAttribute('name'));

    if (checkedIds.length > 0) {
      const interestsParamValue = checkedIds.join();
      location.searchParams.set('interests', interestsParamValue);
    } else {
      // if all or none are checked, remove param from url
      location.searchParams.delete('interests');
    }
    return location;
  }

  function updateTimeOfDay(location) {
    /*
    * @param location URL{Object}
    *
    * @returns URL{object}
    */
    const timeFieldset = document.getElementById('times-fieldset');
    const onlyChecked = timeFieldset.querySelectorAll('input[type="checkbox"]:checked');
    const checkedIds = Array.from(onlyChecked, (checkbox) => checkbox.getAttribute('id'));

    if (checkedIds.length > 0 && checkedIds.length < 3) {
      const timeParamValue = checkedIds.join();
      location.searchParams.set('time_of_day', timeParamValue);
    } else {
      // if all or none are checked, remove param from url
      location.searchParams.delete('time_of_day');
    }
    return location;
  }

  function updateAccessibilityToggle(location) {
    const checked = document.getElementById('checktoggle').checked;

    if (checked) {
      location.searchParams.set('accessible', checked);
    } else {
      location.searchParams.delete('accessible');
    }
    return location;
  }

  function updateSearch() {
    const searchValue = input.value;
    const location = new URL(window.location);
    location.searchParams.set('query', searchValue);
    window.location = location;
  }
  // listen for Enter on search input
  const input = document.querySelector(".js-schedule-search-input");
  input.addEventListener('keypress', (e) => {
    if (e.key === 'Enter' || e.which === 13) {
      updateSearch();
    }
  });

  function toggleEventsAndSites(event) {
    // button styling
    event.preventDefault();
    typeButtons.forEach((btn) => {
      btn.classList.remove('selected');
    });
    event.target.classList.add('selected');

    const location = new URL(window.location);
    location.searchParams.set('type', event.target.id);
    window.location = location;
  }
  const typeButtons = document.querySelectorAll('.js-event-site-toggle');
  typeButtons.forEach((btn) => {
    btn.addEventListener("click", toggleEventsAndSites);
  });

  function initializeCalendar() {
    // datepicker for daily schedule page - slightly different behavior
    const scheduleCal = document.querySelector('.js-contains-schedule-datepicker');
    if (scheduleCal) {
      decrementDay = document.querySelector('.datepicker__prev-day');
      incrementDay = document.querySelector('.datepicker__next-day');
      dateClearButton = document.querySelector('.datepicker__clear-date');
      datepickerInputs = document.querySelectorAll('.datepicker');
      calIconTrigger = document.querySelector('.calendar-icon');

      // flatpickr instance & configs
      datepicker = flatpickr('.datepicker', {
        altInput: true,
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        allowInput: true,
        defaultDate: null,
        minDate: 'today',
        monthSelectorType: 'static',
        appendTo: document.querySelector('.form-input--datepicker'),
        // use the non-native datepicker, bc changing input to type="date" (native behavior) loses placeholder
        disableMobile: true,
        // the onClose: if user clicks into and out of the date field, assume value has been updated
        onClose: () => {
          const cal = scheduleCal.querySelector('.datepicker');
          const calVal = cal.value;
          const location = new URL(window.location);

          if (cal.value) {
            location.searchParams.set('date', calVal);
          } else {
            if (location.searchParams.has('date')) {
              location.searchParams.delete('date');
            }
            if (location.searchParams.has('time_of_day')) {
              location.searchParams.delete('time_of_day');
            }
          }
          window.location = location;
        },
        // the onValueUpdate: using this instead of onChange for cruddy mobile UI
        onValueUpdate: () => {
          const cal = scheduleCal.querySelector('.datepicker');
          const calVal = cal.value;

          const location = new URL(window.location);

          if (cal.value) {
            location.searchParams.set('date', calVal);
          } else {
            if (location.searchParams.has('date')) {
              location.searchParams.delete('date');
            }
            if (location.searchParams.has('time_of_day')) {
              location.searchParams.delete('time_of_day');
            }
          }
          window.location = location;
        },
      });

      if (calIconTrigger) {
        calIconTrigger.addEventListener('click', () => {
          datepicker.toggle();
        });
      }

      incrementDay.addEventListener('click', () => {
        if (datepicker.selectedDates.length) {
          let newDate;
          datepicker.selectedDates.map((date) => {
            newDate = new Date(date.getTime());
            newDate.setDate(newDate.getDate() + 1);
            return newDate;
          });
          datepicker.setDate(newDate, true);
        }
      });

      decrementDay.addEventListener('click', () => {
        if (datepicker.selectedDates.length) {
          let newDate;
          datepicker.selectedDates.map((date) => {
            newDate = new Date(date.getTime());
            newDate.setDate(newDate.getDate() - 1);
            return newDate;
          });
          datepicker.setDate(newDate, true);
        }
      });
    }
  }

  function updateFilterButton(windowLocation) {
    const location = new URL(windowLocation);
    let searchParams = Array.from(location.searchParams);

    const selectedFilters = [];

    for (const [key, value] of location.searchParams) {
      if (key === 'experiences') {
        const experienceTypes = value.split(',');
        const experienceFieldset = document.getElementById('experience-type-fieldset');
        for (const experienceType of experienceTypes) {
          const experienceTypeLabel = experienceFieldset.querySelector(`label[for=${experienceType}-experience]`);
          if (experienceTypeLabel) {
            selectedFilters.push(experienceTypeLabel.innerText);
          }
        }
      } else if (key === 'time_of_day') {
        const timeOfDays = value.split(',');
        const timeFieldset = document.getElementById('times-fieldset');
        for (const timeOfDay of timeOfDays) {
          const timeOfDayLabel = timeFieldset.querySelector(`label[for=${timeOfDay}]`);
          if (timeOfDayLabel) {
            selectedFilters.push(timeOfDayLabel.innerText);
          }
        }
      } else if (key === 'interests') {
        const interests = value.split(',');
        const interestFieldset = document.getElementById('interests-fieldset');
        for (const interest of interests) {
          const interestLabel = interestFieldset.querySelector(`label[for=${interest}-interest]`);
          if (interestLabel) {
            selectedFilters.push(interestLabel.innerText);
          }
        }
      } else if (key === 'accessible' && value === 'true') {
          selectedFilters.push('Accessible');
      }
    }

    // update button innerHTML
    const filterButtonShell = document.querySelector('.daily-schedule-page__filter-button');
    const filterButton = document.getElementById('filter-button');
    const clearFilterButton = document.getElementById('clear-filter-button');
    if (selectedFilters.length > 0) {
      if (selectedFilters.length === 1) {
        filterButton.innerHTML = `${selectedFilters[0]}`;
      } else if (selectedFilters.length === 2) {
        const totalCharLength = selectedFilters[0].length + selectedFilters[1].length;
        if (totalCharLength > 26 && totalCharLength <= 35) {
          // line break if combined char length of interests is greater than filter-button width
          filterButton.innerHTML = `${selectedFilters[0]} &<br> ${selectedFilters[1]}`;
        } else if (totalCharLength > 35) {
          // if combined char length of interests is too large just diplay one
          filterButton.innerHTML = `${selectedFilters[0]} <br>+ 1 more`;
        } else {
          filterButton.innerHTML = `${selectedFilters[0]} & ${selectedFilters[1]}`;
        }
      } else {
        filterButton.innerHTML = `${selectedFilters[0]} + ${selectedFilters.length - 1} more`;
      }

      // add button class
      filterButtonShell.classList.add('has-filters');
      // show clear filter button
      clearFilterButton.style.display = 'block';
      clearFilterButton.setAttribute('aria-hidden', 'false');
    } else {
      // reset button to default
      filterButton.innerHTML = 'Filter Results';
      filterButtonShell.classList.remove('has-filters');
      clearFilterButton.style.display = 'none';
      clearFilterButton.setAttribute('aria-hidden', 'true');
    }
  }

  function clearDateFilter() {
    // clear flatpickr instance date
    datepicker.clear();
    datepicker.close();
  }
  const buttonClearDate = document.querySelector(".js-clear-date-filter");
  if (buttonClearDate) {
    buttonClearDate.addEventListener("click", clearDateFilter);
  }

  function clearFilters() {
    // clear all params except event/site and date
    const location = new URL(window.location);
    location.searchParams.delete('accessibile');
    location.searchParams.delete('time_of_day');
    location.searchParams.delete('experiences');
    location.searchParams.delete('interests');
    location.searchParams.delete('accessible');

    window.location = location;
  }
  const buttonClearFilters = document.querySelector(".js-clear-filters");
  if (buttonClearFilters) {
    buttonClearFilters.addEventListener("click", clearFilters);
  }

  function updateFilters() {
    // close menu
    const menu = document.querySelector('.daily-schedule-page__filter-menu');
    menu.style.transform = 'translateX(400px)';
    menu.setAttribute('aria-hidden', 'true');
    document.querySelector('body').classList.remove('menu-open');
    document.querySelector('html').classList.remove('menu-open');
    // return focus to filter toggle
    document.getElementById('filter-button').focus();

    const currentLocation = new URL(window.location);
    const newLocation = updateAccessibilityToggle(updateTimeOfDay(updateInterests(updateExperienceTypes(currentLocation))));
    // if there are params, the button needs to reflect that
    updateFilterButton(newLocation);
    window.location = newLocation;
  }
  const buttonUpdateFilters = document.querySelector(".js-update-filters");
  if (buttonUpdateFilters) {
    buttonUpdateFilters.addEventListener("click", updateFilters);
  }
}
