// audio embed
const audioBlocks = document.querySelectorAll(".story-block--audio");

if (audioBlocks) {
  audioBlocks.forEach(block => {
    const player = block.querySelector("video");
    player.removeAttribute('controls');
    const playPauseButton = block.querySelector(".js-audio-play-pause");
    const iconMic = block.querySelector(".audio-mic");
    const iconPlay = block.querySelector(".audio-play");
    const iconPause = block.querySelector(".audio-pause");
    const timeTotal = block.querySelector(".js-time-total");
    const timeElapsed = block.querySelector(".js-time-elapsed");
    const animationSVG = block.querySelector(".stamp-outline--light");
    const captionFile = player.textTracks;
    const captionToggle = block.querySelector(".story-block--audio__caption-toggle");
    const captionButtons = block.querySelectorAll(".js-caption-toggle");

    const playPauseMedia = () => {
      if (player.paused) {
        player.play();
        animationSVG.style.animationPlayState = "running";
      } else {
        player.pause();
        animationSVG.style.animationPlayState = "paused";
      }
    };
    const playPauseHover = () => {
      if (player.paused) {
        iconMic.style.display = 'none';
        iconPlay.style.display = 'block';
      } else {
        iconMic.style.display = 'none';
        iconPause.style.display = 'block';
      }
    };
    const playPauseHoverOut = () => {
      if (player.paused) {
        iconPlay.style.display = 'none';
        iconPause.style.display = 'none';
        iconMic.style.display = 'block';
      } else {
        iconPlay.style.display = 'none';
        iconPause.style.display = 'none';
        iconMic.style.display = 'block';
      }
    };
    playPauseButton.addEventListener('click', playPauseMedia);
    playPauseButton.addEventListener('mouseover', playPauseHover);
    playPauseButton.addEventListener('mouseout', playPauseHoverOut);

    const animationReset = () => {
      // animation needs to restart w/ reflow in case the user restarts the player
      animationSVG.classList.remove("js-audio-animate");
      setTimeout(() => {
        animationSVG.classList.add("js-audio-animate");
        animationSVG.style.animationPlayState = "paused";
      }, 500);
    };
    player.addEventListener('ended', animationReset);

    const setElapsedTime = () => {
      let minutes = Math.floor(player.currentTime / 60);
      let seconds = Math.floor(player.currentTime - minutes * 60);
      let minuteValue;
      let secondValue;
      if (minutes < 10) {
        minuteValue = '0' + minutes;
      } else {
        minuteValue = minutes;
      }
      if (seconds < 10) {
        secondValue = '0' + seconds;
      } else {
        secondValue = seconds;
      }
      let elapsedValue = minuteValue + ':' + secondValue;
      timeElapsed.innerHTML = elapsedValue;
    };
    player.addEventListener('timeupdate', setElapsedTime);

    const setTotalTime = () => {
      if (player.duration && isFinite(player.duration)) {
        // for display purposes
        const minutes = parseInt(player.duration / 60, 10);
        const seconds = parseInt(player.duration % 60);
        timeTotal.style.display = "inline-block";
        timeTotal.innerHTML = ` / ${minutes}:${seconds}`;
        // for animation purposes
        const secondsDuration = parseInt(player.duration);
        animationSVG.style.animationDuration = `${secondsDuration}s`;
      } else {
        // BUG: safari on mac has a bug that returns the player duration as infinite if the player src is set dynamically
        // if that's the case, just hide the animated SVG and leave timeTotal value blank
        animationSVG.style.display = "none";
      }
    };
    player.addEventListener('loadedmetadata', setTotalTime);
    // just in case safari decides to eventually serve up the duration value...
    player.addEventListener("durationchange", function() {
      setTotalTime();
    });

    if (captionFile && captionFile[0]) {
      captionFile[0].mode = 'hidden'; // turn off captions initially until user turns them on
      captionToggle.style.display = "flex"; // show the toggle controls

      const toggleCaptions = (event) => {
        // button styling
        event.preventDefault();
        captionButtons.forEach((btn) => {
          btn.classList.remove('selected');
        });
        event.target.classList.add('selected');

        // caption show/hide
        if (event.target.id == 'captions-on') {
          captionFile[0].mode = 'showing';
        } else {
          captionFile[0].mode = 'hidden';
        }
      }
      captionButtons.forEach((btn) => {
        btn.addEventListener("click", toggleCaptions);
      });
    } else {
      // hide the toggle if there are no captions to display
      captionToggle.style.display = "none";
    }
  });
}
