// apply accessibility helpers to 3rd party Abila donation forms

const abilaForms = document.querySelectorAll(".membership-form");
if (abilaForms) {
  abilaForms.forEach(abilaForm => {
    // check for req fields once form fully loads, interval of 2x per second
    function findFields () {
      const intervalForReqs = setInterval(() => {
        if (abilaForm.querySelectorAll(".k_required")) {
          // stop checking
          clearInterval(intervalForReqs);

          // text inputs
          const reqInputs = abilaForm.querySelectorAll("input.k_text.k_required");
          Array.from(reqInputs).forEach(input => {
            input.setAttribute("aria-required", "true");
            input.setAttribute("required", "true");
          });
          const reqInputLabels = abilaForm.querySelectorAll("label.k_text.k_required");
          Array.from(reqInputLabels).forEach(label => {
            const newSpan = document.createElement("span");
            newSpan.innerHTML = "*";
            label.appendChild(newSpan);
          });

          // selects
          const reqSelects = abilaForm.querySelectorAll("select.k_select.k_required");
          Array.from(reqSelects).forEach(select => {
            select.setAttribute("aria-required", "true");
            select.setAttribute("required", "true");
          });
          const reqSelectLabels = abilaForm.querySelectorAll("label.k_select.k_required");
          Array.from(reqSelectLabels).forEach(label => {
            const newSpan = document.createElement("span");
            newSpan.innerHTML = "*";
            label.appendChild(newSpan);
          });

        }
      }, 500);
    }
    // run the check when modal opens
    findFields();
  });
}
