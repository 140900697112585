import axios from 'axios';

axios({
  method: 'get',
  url: '/api/v2/banner/',
  responseType: 'text'
}).then(response => {
  if (response.status === 200) {
    const nav = document.querySelector('nav');
    if (nav) {
      nav.insertAdjacentHTML('beforebegin', response.data);

      const banner = document.querySelector('.nav-message');
      if (banner) {
        const bannerCloser = document.querySelector('.banner-close');
        bannerCloser.addEventListener('click', () => {
          banner.classList.remove('nav-message-showing');
          axios.get(bannerCloser.dataset.url);
        });
      }
    }
  }
});
