const categoryToggle = document.querySelector(".learn-category__dropdown-button");
const categoryContainer = document.querySelector(".learn-category__dropdown-list");

if (categoryToggle && categoryContainer) {
  const toggleCategories = (e) => {
    e.preventDefault();
    categoryToggle.classList.toggle('expanded');
    const pressed = categoryToggle.getAttribute("aria-pressed") === "true";
    categoryToggle.setAttribute("aria-pressed", !pressed);
    categoryContainer.classList.toggle('expanded');
  };

  categoryToggle.addEventListener('click', toggleCategories);
}
