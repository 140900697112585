const toggleBtn = document.querySelector('.hours__toggle');
const hoursContainer = document.querySelector('.hours__list');

if (toggleBtn && hoursContainer) {
  const toggleHours = (e) => {
    e.preventDefault();

    toggleBtn.classList.toggle('expanded');
    const pressed = toggleBtn.getAttribute("aria-pressed") === "true";
    toggleBtn.setAttribute("aria-pressed", !pressed);
    hoursContainer.classList.toggle('expanded');

    if (toggleBtn.classList.contains('expanded')) {
      toggleBtn.querySelector('p').innerHTML = 'Hide Hours';
      toggleBtn.querySelector('svg').style.transform = 'rotate(180deg)';
    } else {
      toggleBtn.querySelector('p').innerHTML = 'Show Hours';
      toggleBtn.querySelector('svg').style.transform = 'none';
    }
  };

  toggleBtn.addEventListener('click', toggleHours);
}
