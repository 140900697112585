const persistentTitle = document.querySelector(".story-header__persistent-title");
const persistentStamp = document.querySelector(".story-header__stamp");
const persistentNav = document.querySelector(".story-nav");
const storyIntro = document.querySelector(".story-intro");
const sectionArea = document.querySelector(".story-sections");
const sections = document.querySelectorAll(".story-section");

// persistent title header
if (storyIntro && persistentTitle) {
  const fixedHeader = () => {
    const distFromTop = storyIntro.offsetTop;
    if (document.body.scrollTop >= distFromTop || document.documentElement.scrollTop >= distFromTop) {
      persistentTitle.classList.add("in-view");
    } else {
      persistentTitle.classList.remove("in-view");
    }
  };
  window.addEventListener('scroll', fixedHeader);
}

// persistent stamp initial scroll
if (persistentStamp) {
  const rollStamp = () => {
    if (document.body.scrollTop >= 100 || document.documentElement.scrollTop >= 100) {
      persistentStamp.classList.add("half-display");
    } else {
      persistentStamp.classList.remove("half-display");
    }
  };
  window.addEventListener('scroll', rollStamp);
}

// hover reveals link on persistent stamp + title
if (persistentStamp && persistentTitle) {
  const originalTitle = persistentTitle.innerHTML;
  const applyHover = () => {
    persistentTitle.innerHTML = "Colonial Williamsburg";
  };
  const removeHover = () => {
    persistentTitle.innerHTML = originalTitle;
  };
  persistentStamp.addEventListener('mouseover', applyHover);
  persistentTitle.addEventListener('mouseover', applyHover);
  persistentStamp.addEventListener('mouseout', removeHover);
  persistentTitle.addEventListener('mouseout', removeHover);
}

// persistent gradient over intro text
if (storyIntro) {
  const gradient = storyIntro.querySelector(".story-intro__gradient");
  const fixedGradient = () => {
    const positionTop = storyIntro.offsetTop;
    const positionBottom = positionTop + storyIntro.offsetHeight - 200;
    if ( (document.body.scrollTop >= positionTop && document.body.scrollTop < positionBottom) || (document.documentElement.scrollTop >= positionTop && document.documentElement.scrollTop < positionBottom) ) {
      gradient.classList.add("fix-top");
    } else {
      gradient.classList.remove("fix-top");
    }
  };
  window.addEventListener('scroll', fixedGradient);
}

// color of persistent title
if (sectionArea) {
  const changeTitleColor = () => {
    const distFromTop = sectionArea.offsetTop;
    if (document.body.scrollTop >= distFromTop || document.documentElement.scrollTop >= distFromTop) {
      persistentTitle.classList.add("light-bg");
    } else {
      persistentTitle.classList.remove("light-bg");
    }
  }
  window.addEventListener('scroll', changeTitleColor);
}

if (sections) {
  sections.forEach(section => {
    const sectionGradient = section.querySelector(".story-section__gradient");
    const sectionYear = section.querySelector(".story-section__persistent-year");
    const happeningsButton = section.querySelector(".story-section__sidebar--right");
    const storyBlockWrapper = section.querySelector(".story-section__scroll-content");

    // persistent scroll gradient
    if (sectionGradient) {
      const fixedGradient = () => {
        const top = section.offsetTop;
        const pageHeight = window.innerHeight;
        const positionFromBottom = sectionGradient.getBoundingClientRect().top;

        // conditional for whether the section top = top of screen
        if (document.body.scrollTop >= top || document.documentElement.scrollTop >= top) {
          sectionGradient.classList.add("fix-top");
        } else {
          sectionGradient.classList.remove("fix-top");
        }

        // if bottom of section is at top of viewport, fade the gradient
        const heightOfSection = section.offsetHeight;
        const comparisonHeight = Math.abs(section.getBoundingClientRect().top);
        if (heightOfSection < comparisonHeight) {
          sectionGradient.classList.add("fade-away");
        } else {
          sectionGradient.classList.remove("fade-away");
        }
      };
      window.addEventListener('scroll', fixedGradient);
    }

    // persistent year
    if (sectionYear) {

      const fixedYear = () => {
        const top = section.offsetTop;
        const pageHeight = window.innerHeight;
        const positionFromBottom = sectionYear.getBoundingClientRect().top;

        // conditional for whether the section top = top of screen
        if (document.body.scrollTop >= top || document.documentElement.scrollTop >= top) {
          sectionYear.classList.add("fix-top");
        } else {
          sectionYear.classList.remove("fix-top");
        }

        // if bottom of section is higher than bottom of viewport, fade the year
        const heightOfSection = section.offsetHeight;
        const comparisonHeight = Math.abs(section.getBoundingClientRect().top - pageHeight);
        if (heightOfSection < comparisonHeight) {
          sectionYear.classList.add("fade-away");
        } else {
          sectionYear.classList.remove("fade-away");
        }

      };
      window.addEventListener('scroll', fixedYear);

    }

    // persistent happenings button
    if (happeningsButton) {
      const fixedButton = () => {
        const position = section.offsetTop;
        const pageHeight = window.innerHeight;

        // conditional for whether the section top = top of screen
        if (document.body.scrollTop >= position || document.documentElement.scrollTop >= position) {
          happeningsButton.classList.add("reveal");
        } else {
          happeningsButton.classList.remove("reveal");
        }

        // if bottom of section is higher than bottom of viewport, hide the button
        const heightOfSection = section.offsetHeight;
        const comparisonHeight = Math.abs(section.getBoundingClientRect().top - pageHeight);
        if (heightOfSection < comparisonHeight) {
          happeningsButton.classList.add("bump");
        } else {
          happeningsButton.classList.remove("bump");
        }

      };
      window.addEventListener('scroll', fixedButton);
    }
  });
}

if (persistentNav && sections) {
  // only show if 3+ sections present
  const sectionCount = sections.length;
  if (sectionCount >= 3) {
    // make first dot "selected" by default
    const sectionDots = document.querySelectorAll(".story-nav__dot");
    sectionDots[0].classList.add("selected");

    const getHeight = () => {
      const pageHeight = window.innerHeight;
      persistentNav.style.height = `${pageHeight - 260}px`;
    };
    window.addEventListener('resize', getHeight);
    getHeight();

    const fixedNav = () => {
      const position = sectionArea.offsetTop;

      // conditional for whether the section top = top of screen
      if (document.body.scrollTop >= position || document.documentElement.scrollTop >= position) {
        persistentNav.classList.add("reveal");
      } else {
        persistentNav.classList.remove("reveal");
      }
    };
    window.addEventListener('scroll', fixedNav);

    // home icon clicked
    const homeDot = document.querySelector(".story-nav__home");
    const homeScreen = document.querySelector(".story-header");
    const scrollHome = () => { homeScreen.scrollIntoView({ behavior: 'smooth' }); };
    homeDot.addEventListener('click', scrollHome);

    // section dot clicked
    sectionDots.forEach(dot => {
      const dotIndex = dot.getAttribute("data-section");
      const scrollSection = () => {
        // change the dot appearance
        const currentDot = document.querySelector(".story-nav__dot.selected");
        currentDot.classList.remove("selected");
        dot.classList.add("selected");
        // change the section scroll
        sections.forEach(sec => {
          if (dotIndex === sec.getAttribute("data-dot")) {
            window.scrollTo({ top: sec.offsetTop + 10, behavior: 'smooth' });
          }
        });
      };
      dot.addEventListener('click', scrollSection);
    });

    // adjust nav dots with natural scroll position
    // if bottom of section is higher than bottom of viewport, hide the button
    sections.forEach(section => {
      const evaluateSection = () => {
        const top = section.offsetTop;
        const pageHeight = window.innerHeight;

        // conditional for whether the section top = top of screen
        if (document.body.scrollTop >= top || document.documentElement.scrollTop >= top) {
          const sectionIndex = section.getAttribute("data-dot");
          const selectedDot = persistentNav.querySelector(".selected");
          const selectedDotIndex = selectedDot.getAttribute("data-section");

          if (!section.classList.contains("off-screen") && (sectionIndex != selectedDotIndex)) {
            sectionDots.forEach(dot => {
              if (dot.getAttribute("data-section") === sectionIndex) {
                dot.classList.add("selected");
                selectedDot.classList.remove("selected");
              }
            });
          }
        }

        // if bottom of section is higher than bottom of viewport, fade the year
        const heightOfSection = section.offsetHeight;
        const comparisonHeight = Math.abs(section.getBoundingClientRect().top - pageHeight);
        if (heightOfSection < comparisonHeight) {
          section.classList.add("off-screen");
        } else {
          section.classList.remove("off-screen");
        }
      };
      window.addEventListener('scroll', evaluateSection);
    });
  }
}
