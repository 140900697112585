// handle the logic of multiple tiers of checkboxes (aka mixed state)

const multiFieldsets = document.querySelectorAll('.multi-checkbox__fieldset');
if (multiFieldsets) {
  multiFieldsets.forEach((multiFieldset) => {
    const multiTrigger = multiFieldset.querySelector('.multi-checkbox__trigger');
    const multiDropdown = multiFieldset.querySelector('.multi-checkbox__container');
    const toggleContainer = () => {
      if (multiTrigger.classList.contains('expanded')) {
        multiTrigger.setAttribute('aria-expanded', 'false');
        multiTrigger.classList.remove('expanded');
        multiDropdown.setAttribute('hidden', 'true');
        // capture ESC key to close dropdown when it is expanded and return focus to trigger
        document.body.addEventListener('keyup', (e) => {
          if (e.keyCode === 27) {
            multiTrigger.setAttribute('aria-expanded', 'false');
            multiTrigger.classList.remove('expanded');
            multiTrigger.focus();
            multiDropdown.setAttribute('hidden', 'true');
          }
        });
      } else {
        multiTrigger.setAttribute('aria-expanded', 'true');
        multiTrigger.classList.add('expanded');
        multiDropdown.removeAttribute('hidden');
      }
    };
    multiTrigger.addEventListener('click', toggleContainer);

    // capture how many checkboxes are selected
    // update the input button with total # selected
    const mixedCheckboxes = multiFieldset.querySelectorAll('[role="checkbox"]');
    const checkboxes = multiFieldset.querySelectorAll('input[type="checkbox"]');
    const updateSelection = () => {
      const onlyChecked = multiFieldset.querySelectorAll('input[type="checkbox"]:checked');
      const totalSelected = onlyChecked.length;
      if (totalSelected === 9) {
        multiTrigger.value = 'All';
      } else if (totalSelected === 0) {
        multiTrigger.value = 'None Selected';
      } else {
        multiTrigger.value = `${totalSelected} Selected`;
      }

      // mixed state checkboxes
      if (mixedCheckboxes) {
        mixedCheckboxes.forEach((mixedCheckbox) => {
          // RESPONSIVE LOGIC: set the state of parent checkbox based on nested checkboxes
          const nestedItems = mixedCheckbox.nextElementSibling.children;
          let checkedInputs = [];
          let uncheckedInputs = [];
          Array.from(nestedItems).forEach((li) => {
            const nestedInput = li.querySelector('input[type="checkbox"]');
            if (nestedInput.checked) {
              checkedInputs.push(nestedInput);
            } else {
              uncheckedInputs.push(nestedInput);
            }
          });
          if (uncheckedInputs.length === 0) {
            mixedCheckbox.classList.add('all-selected');
            mixedCheckbox.classList.remove('none-selected');
            mixedCheckbox.classList.remove('some-selected');
          }
          if (checkedInputs.length === 0) {
            mixedCheckbox.classList.add('none-selected');
            mixedCheckbox.classList.remove('some-selected');
            mixedCheckbox.classList.remove('all-selected');
          }
          if (uncheckedInputs.length > 0 && checkedInputs.length > 0) {
            mixedCheckbox.classList.add('some-selected');
            mixedCheckbox.classList.remove('all-selected');
            mixedCheckbox.classList.remove('none-selected');
          }
        });
      }
    };
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', updateSelection);
    });

    // CONTROL LOGIC: set the state of nested checkboxes based on the parent
    if (mixedCheckboxes) {
      mixedCheckboxes.forEach((mixedCheckbox) => {
        const updateNestedItems = (e) => {
          e.preventDefault();
          const nested = mixedCheckbox.nextElementSibling.children;
          if (mixedCheckbox.classList.contains('all-selected')) {
            mixedCheckbox.classList.add('none-selected');
            mixedCheckbox.classList.remove('all-selected');
            Array.from(nested).forEach((li) => {
              const input = li.querySelector('input[type="checkbox"]');
              input.checked = false;
            });
          } else if (mixedCheckbox.classList.contains('none-selected') || mixedCheckbox.classList.contains('some-selected')) {
            mixedCheckbox.classList.add('all-selected');
            mixedCheckbox.classList.remove('none-selected');
            mixedCheckbox.classList.remove('some-selected');
            Array.from(nested).forEach((li) => {
              const input = li.querySelector('input[type="checkbox"]');
              input.checked = true;
            });
          }
          // update total selected on label
          updateSelection();
        };
        // listen for click / space / enter
        mixedCheckbox.addEventListener('click', updateNestedItems);
        mixedCheckbox.addEventListener("keypress", (e) => {
          if (e.key === "Enter" || e.which === 13 || e.key === " " || e.which === 32) {
            e.preventDefault();
            updateNestedItems();
          }
        });
      });
    }
  });
}

const filterFieldsets = document.querySelectorAll(".filter-menu__fieldset");
if (filterFieldsets) {
  filterFieldsets.forEach((filter) => {
    const dropdowns = filter.querySelectorAll('.filter-menu__checkbox-primary-wrapper');
    const mixedCheckboxes = filter.querySelectorAll('[role="checkbox"]');
    const checkboxes = filter.querySelectorAll('input[type="checkbox"]');

    const updateMixedCheckbox = () => {
      if (dropdowns) {
        dropdowns.forEach((dropdown) => {
          const mixedCheckbox = dropdown.querySelector('[role="checkbox"]');
          const dropdownToggle = dropdown.querySelector('button');
          // nested dropdown toggle
          const toggleNested = () => {
            const nested = dropdown.nextElementSibling;
            if (nested.classList.contains('expanded')) {
              dropdownToggle.setAttribute('aria-pressed', 'true');
              nested.setAttribute('aria-hidden', 'false');
              nested.classList.remove('expanded');
            } else {
              dropdownToggle.setAttribute('aria-pressed', 'false');
              nested.setAttribute('aria-hidden', 'true');
              nested.classList.add('expanded');
            }
          }
          // listen for click / space / enter
          dropdownToggle.addEventListener('click', toggleNested);
          dropdownToggle.addEventListener("keypress", (e) => {
            if (e.key === "Enter" || e.which === 13 || e.key === " " || e.which === 32) {
              toggleNested();
            }
          });

          // RESPONSIVE LOGIC: set the state of parent checkbox based on nested checkboxes
          const nestedItems = dropdown.nextElementSibling.children;
          let checkedInputs = [];
          let uncheckedInputs = [];
          Array.from(nestedItems).forEach((li) => {
            const nestedInput = li.querySelector('input[type="checkbox"]');
            if (nestedInput.checked) {
              checkedInputs.push(nestedInput);
            } else {
              uncheckedInputs.push(nestedInput);
            }
          });
          if (uncheckedInputs.length === 0) {
            mixedCheckbox.classList.add('all-selected');
            mixedCheckbox.classList.remove('none-selected');
            mixedCheckbox.classList.remove('some-selected');
          }
          if (checkedInputs.length === 0) {
            mixedCheckbox.classList.add('none-selected');
            mixedCheckbox.classList.remove('some-selected');
            mixedCheckbox.classList.remove('all-selected');
          }
          if (uncheckedInputs.length > 0 && checkedInputs.length > 0) {
            mixedCheckbox.classList.add('some-selected');
            mixedCheckbox.classList.remove('all-selected');
            mixedCheckbox.classList.remove('none-selected');
          }
        });
      }
    };
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', updateMixedCheckbox);
    });
    updateMixedCheckbox();

    // mixed state checkboxes
    if (dropdowns) {
      // CONTROL LOGIC: set the state of nested checkboxes based on the parent
      dropdowns.forEach((dropdown) => {
        const mixedCheckbox = dropdown.querySelector('[role="checkbox"]');
        const updateNestedItems = (e) => {
          e.preventDefault();
          const nested = dropdown.nextElementSibling.children;
          if (mixedCheckbox.classList.contains('all-selected')) {
            mixedCheckbox.classList.add('none-selected');
            mixedCheckbox.classList.remove('all-selected');
            Array.from(nested).forEach((li) => {
              const input = li.querySelector('input[type="checkbox"]');
              input.checked = false;
            });
          } else if (
            mixedCheckbox.classList.contains('none-selected') ||
            mixedCheckbox.classList.contains('some-selected')
          ) {
            mixedCheckbox.classList.add('all-selected');
            mixedCheckbox.classList.remove('none-selected');
            mixedCheckbox.classList.remove('some-selected');
            Array.from(nested).forEach((li) => {
              const input = li.querySelector('input[type="checkbox"]');
              input.checked = true;
            });
          }
        };
        // listen for click and also for space key
        mixedCheckbox.addEventListener('click', updateNestedItems);
        mixedCheckbox.addEventListener('keypress', updateNestedItems);
      });
    }
  });
}
