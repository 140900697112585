const mapPage = document.querySelector(".map-page");
if (mapPage) {

  function buildInfoWindowHtml(location) {
    const experienceTypeHtml = location.experience_type ? `<p class="map-page__location-list__label pin"><svg class="map-page__location-list__experience-icon--outer js-randomize-shape ${location.cms_color}" aria-hidden="true"><use xlink:href="#seal-1" /></svg><svg class="map-page__location-list__experience-icon" aria-hidden="true"><use xlink:href="#${location.experience_type_slug}" /></svg><span>${location.experience_type}</span></p>` : '';
    const hoursHtml = location.todays_hours ? `<p class="map-page__location-list__hours"><svg class="svg" width="20" height="20"><use xlink:href="#clock" /></svg>${location.todays_hours}</p>` : '';
    const accessibleHtml = location.is_full_handicap_accessible ? `<svg class="map-page__location-list__accessibility-icon" aria-label="accessible location"><use xlink:href="#accessible" /></svg>` : '';
    const html = `<div>
      ${experienceTypeHtml}
      <p class="map-page__location-list__name--pin">${location.title}</p>
      ${hoursHtml}
      <div class="map-page__location-list__row">
        ${accessibleHtml}
        <a class="map-page__location-list__link--pin" href="${location.url}">More Information</a>
      </div>
    </div>
    `;
    return html;
  }

  const markerEmsIdMap = {};

  // Map Initialization and Customization --------------------------------------------------
  // give initMap a global scope
  window.initMap = function initMap() {
    const bounds = new google.maps.LatLngBounds();
    const styledMapType = new google.maps.StyledMapType(
      [
        {
          featureType: "administrative",
          elementType: "labels",
          stylers: [{
              visibility: "off"
          }]
        }, {
          featureType: "poi",
          elementType: "labels",
          stylers: [{
              visibility: "off"
          }]
        }, {
          featureType: "water",
          elementType: "labels",
          stylers: [{
              visibility: "off"
          }]
        }, {
          featureType: "road",
          elementType: "labels",
          stylers: [{
              visibility: "on"
          }]
        }
      ],
      {
        name: "Map"
      }
    );

    const map = new google.maps.Map(document.getElementById('map'), {
      center: {lat: 37.27, lng: -76.7},
      zoom: 16,
      mapTypeControl: false,
      mapTypeControlOptions: {
        mapTypeIds: ["custom_map"],
        position: google.maps.ControlPosition.LEFT_BOTTOM
      }
    });

    //Associate the styled map with the MapTypeId and set it to display.
    map.mapTypes.set('custom_map', styledMapType);
    map.setMapTypeId('custom_map');

    const locationListContainer = document.querySelector('.map-page__location-list');
    const infoWindow = new google.maps.InfoWindow();
    const redPin = '../static/images/pin.png';
    const blackPin = '../static/images/pin-black.png';

    for (const marker_data of MAP_MARKER_DATA) {
      bounds.extend(marker_data.position);
      const marker = new google.maps.Marker({
        position: marker_data.position,
        map: map,
        title: marker_data.title,
        icon: redPin,
      });
      markerEmsIdMap[marker_data.ems_id] = marker;

      // marker click event
      google.maps.event.addListener(marker, 'click', (function(marker, marker_data) {
        return function() {
          // center the map on the pin
          map.panTo(marker.getPosition());
          // bring pin to forefront if applicable
          marker.setZIndex(9999);
          // set and reveal info window
          infoWindow.setContent(buildInfoWindowHtml(marker_data));
          infoWindow.open(map, marker);
          // "select" the corresponding location in list view
          const selectedLocationListItems = document.querySelectorAll('.js-location-list-item.selected');
          for (const selectedLocationListItem of selectedLocationListItems) {
            selectedLocationListItem.classList.remove('selected');
          }
          const newSelectedLocationListItem = document.querySelector(`.js-location-list-item-${marker_data.ems_id}`);
          newSelectedLocationListItem.classList.add('selected');

          // if list view is open, scroll the list to the selected item
          if (locationListContainer.classList.contains('open')) {
            locationListContainer.scrollTo({ top: newSelectedLocationListItem.offsetTop, behavior: 'smooth' });
          }
        }
      })(marker, marker_data));

      // Hover
      google.maps.event.addListener(marker, 'mouseover', (function(marker) {
        return function() {
          marker.setIcon(blackPin);
        }
      })(marker));
      google.maps.event.addListener(marker, 'mouseout', (function(marker) {
        return function() {
          marker.setIcon(redPin);
        }
      })(marker));
    }

    const params = new URLSearchParams(window.location.search);
    const selectedLocation = params.get('location');
    if (selectedLocation) {
      const locationEmsId = parseInt(selectedLocation);
      const locationMarker = markerEmsIdMap[locationEmsId];
      if (locationMarker) {
        google.maps.event.trigger(locationMarker, 'click');
        map.setZoom(17);
      }
    }

    // clicking on location list item should "select" associated pin
    // we're getting list item ID based on data attr,
    // we're getting marker based on position in array of all markers
    const locationList = document.querySelectorAll('.js-location-list-item');
    locationList.forEach(locationListItem => {
      const showOnMap = () => {
        const selectedLocationListItems = document.querySelectorAll('.js-location-list-item.selected');
        for (const selectedLocationListItem of selectedLocationListItems) {
          selectedLocationListItem.classList.remove('selected');
        }
        locationListItem.classList.add('selected');
        const locationEmsId = parseInt(locationListItem.dataset.locationId);
        const locationMarker = markerEmsIdMap[locationEmsId];
        google.maps.event.trigger(locationMarker, 'click');
      };
      locationListItem.addEventListener('click', showOnMap);
    });

    function openList(event) {
      // slide menu
      const menu = document.querySelector('.map-page__location-list');
      const menuOpen = menu.classList.contains('open');
      menu.classList.toggle('open');
      menu.setAttribute('aria-hidden', !menuOpen);
      openListButton.classList.toggle('pressed');
      openListButton.setAttribute('aria-pressed', menuOpen);
      if (openListButton.classList.contains('pressed')) {
        openListButton.innerHTML = `Hide List View (${Object.keys(markerEmsIdMap).length})`;
      } else {
        openListButton.innerHTML = `Show List View (${Object.keys(markerEmsIdMap).length})`;
      }
    }

    const openListButton = document.querySelector(".js-toggle-list-menu");
    if (Object.keys(markerEmsIdMap).length > 0) {
      openListButton.addEventListener("click", openList);
    }

    // Center the map to fit all markers on the screen (unless zoomed in via selected location)
    if (!selectedLocation && Object.keys(markerEmsIdMap).length > 0) {
      map.fitBounds(bounds);
    }
  }


  // Map Filtering ----------------------------------------------------------------

  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);

    // set filter button initial state
    updateFilterButton(window.location);

    const accessible = params.get('accessible');
    const experience_types = params.get('experiences');

    if (accessible) {
      const value = accessible === 'true' ? true : false;
      document.getElementById('checktoggle').checked = value;
    }

    if (experience_types) {
      const experienceTypeCheckboxes = document.querySelectorAll('#experience-type-fieldset input[type="checkbox"]');
      const selectedExperienceTypes = experience_types.split(',');

      if (experienceTypeCheckboxes) {
        // if experience_type param present, uncheck all items before updating selected
        for (const checkbox of experienceTypeCheckboxes) {
          checkbox.checked = (selectedExperienceTypes.indexOf(checkbox.name) >= 0);
        }
      }
    }
  }

  function openFilterMenu(event) {
    // prevent background scroll
    document.querySelector('body').classList.add('menu-open');
    document.querySelector('html').classList.add('menu-open');
    // slide menu
    const menu = document.querySelector('.daily-schedule-page__filter-menu');
    const closeBtn = menu.querySelector('.js-map-filter-menu-close');
    menu.classList.add("open");
    menu.style.transform = 'translateX(0)';
    menu.setAttribute('aria-hidden', 'false');

    // close menu
    // listen for click, enter key, or space key
    const closeMenu = () => {
      menu.classList.remove("open");
      menu.style.transform = 'translateX(400px)';
      menu.setAttribute('aria-hidden', 'true');
      document.querySelector('body').classList.remove('menu-open');
      document.querySelector('html').classList.remove('menu-open');
      // return focus to filter toggle
      event.target.focus();
    };

    closeBtn.addEventListener('click', closeMenu);
    closeBtn.addEventListener('keypress', (e) => {
      if (e.key === 'Enter' || e.which === 13 || e.key === ' ' || e.which === 32) {
        e.preventDefault();
        closeMenu();
      }
    });
  }
  const openButton = document.querySelector(".js-open-map-filter-menu");
  openButton.addEventListener("click", openFilterMenu);

  function updateExperienceTypes(location) {
    /*
    * @param location URL{Object} current window location
    *
    * @returns URL{object}
    */
    const experienceFieldset = document.getElementById('experience-type-fieldset');
    const onlyChecked = experienceFieldset.querySelectorAll('input[type="checkbox"]:checked');
    const checkedIds = Array.from(onlyChecked, (checkbox) => checkbox.getAttribute('name'));

    if (checkedIds.length > 0) {
      const experienceParamValue = checkedIds.join();
      location.searchParams.set('experiences', experienceParamValue);
    } else {
      // if all or none are checked, remove param from url
      location.searchParams.delete('experiences');
    }
    return location;
  }

  function updateAccessibilityToggle(location) {
    const checked = document.getElementById('checktoggle').checked;
    if (checked) {
      location.searchParams.set('accessible', checked);
    } else {
      location.searchParams.delete('accessible');
    }
    return location;
  }

  function updateFilterButton(windowLocation) {
    const location = new URL(windowLocation);
    let searchParams = Array.from(location.searchParams);

    const selectedFilters = [];

    for (const [key, value] of location.searchParams) {
      if (key === 'experiences') {
        const experienceTypes = value.split(',');
        const experienceFieldset = document.getElementById('experience-type-fieldset');
        for (const experienceType of experienceTypes) {
          const experienceTypeLabel = experienceFieldset.querySelector(`label[for=${experienceType}-experience]`);
          if (experienceTypeLabel) {
            selectedFilters.push(experienceTypeLabel.innerText);
          }
        }
      } else if (key === 'accessible' && value === 'true') {
          selectedFilters.push('Accessible');
      }
    }

    // update button innerHTML
    const filterButtonShell = document.querySelector('.map-page__filter-button');
    const filterButton = document.getElementById('map-filter-button');
    const clearFilterButton = document.getElementById('clear-map-filter-button');
    if (selectedFilters.length > 0) {
      if (selectedFilters.length === 1) {
        filterButton.innerHTML = `${selectedFilters[0]}`;
      } else if (selectedFilters.length === 2) {
        const totalCharLength = selectedFilters[0].length + selectedFilters[1].length;
        if (totalCharLength > 26 && totalCharLength <= 35) {
          // line break if combined char length of interests is greater than filter-button width
          filterButton.innerHTML = `${selectedFilters[0]} &<br> ${selectedFilters[1]}`;
        } else if (totalCharLength > 35) {
          // if combined char length of interests is too large just diplay one
          filterButton.innerHTML = `${selectedFilters[0]} <br>+ 1 more`;
        } else {
          filterButton.innerHTML = `${selectedFilters[0]} & ${selectedFilters[1]}`;
        }
      } else {
        filterButton.innerHTML = `${selectedFilters[0]} + ${selectedFilters.length - 1} more`;
      }

      // add button class
      filterButtonShell.classList.add('has-filters');
      // show clear filter button
      clearFilterButton.style.display = 'block';
      clearFilterButton.setAttribute('aria-hidden', 'false');
    } else {
      // reset button to default
      filterButton.innerHTML = 'Filter Locations';
      filterButtonShell.classList.remove('has-filters');
      clearFilterButton.style.display = 'none';
      clearFilterButton.setAttribute('aria-hidden', 'true');
    }
  }

  function clearFilters() {
    // clear all params
    const location = new URL(window.location);
    location.searchParams.delete('accessible');
    location.searchParams.delete('experiences');
    window.location = location;
  }

  const buttonClearFilters = document.querySelector(".js-clear-map-filters");
  if (buttonClearFilters) {
    buttonClearFilters.addEventListener("click", clearFilters);
  }

  function updateFilters() {
    // close menu
    const menu = document.querySelector('.daily-schedule-page__filter-menu');
    menu.style.transform = 'translateX(400px)';
    menu.setAttribute('aria-hidden', 'true');
    document.querySelector('body').classList.remove('menu-open');
    document.querySelector('html').classList.remove('menu-open');
    // return focus to filter toggle
    document.getElementById('map-filter-button').focus();

    const location = new URL(window.location);
    // remove any remaining location ID once user begins filtering
    location.searchParams.delete('location');
    // if there are params, the button needs to reflect that
    const windowLocation = updateAccessibilityToggle(updateExperienceTypes(location));
    updateFilterButton(windowLocation);
    window.location = windowLocation;
  }

  const buttonUpdateFilters = document.querySelector(".js-update-map-filters");
  if (buttonUpdateFilters) {
    buttonUpdateFilters.addEventListener("click", updateFilters);
  }
}
