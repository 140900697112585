// handling toggle of hamburger nav menu

// get viewport height as value for our vh variable
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

const nav = document.querySelector(".nav");
const navToggle = document.querySelector(".nav--mobile");
const navContainer = document.querySelector(".nav__small-links-container");
const navMenu = document.querySelector(".nav__small-links.mobile");
if (navToggle && navMenu) {

  // Recalculate vh if window is resized
  window.addEventListener('resize', () => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  const toggleMenu = () => {
    const pressed = navToggle.getAttribute("aria-pressed") === "true";
    navToggle.setAttribute("aria-pressed", !pressed);
    navMenu.classList.toggle('expanded');
    navContainer.classList.toggle("expanded");
    nav.classList.toggle('z-above');
    // prevent background scroll
    document.querySelector("body").classList.toggle("menu-open");
    document.querySelector("html").classList.toggle("menu-open");

    if (!pressed) {
      navToggle.querySelector("use").setAttribute("xlink:href", "#close");
      document.querySelector(".nav").classList.add("opaque");
    } else {
      navToggle.querySelector("use").setAttribute("xlink:href", "#hamburger-menu");
    }
  };

  // listen for click, enter key, or space key
  navToggle.addEventListener("click", toggleMenu);
  navToggle.addEventListener("keypress", (e) => {
    if (e.key === "Enter" || e.which === 13 || e.key === " " || e.which === 32) {
      e.preventDefault();
      toggleMenu();
    }
  });

  const primaryLinks = document.querySelectorAll(".mobile-mega-menu");
  Array.from(primaryLinks).forEach(primaryLink => {
    const subMenuTrigger = primaryLink.querySelector(".mega-menu__mobile-toggle");
    const subMenu = primaryLink.querySelector(".mega-menu__items");
    const toggleSubMenu = () => {
      const pressed = subMenuTrigger.getAttribute("aria-pressed") === "true";
      subMenuTrigger.setAttribute("aria-pressed", !pressed);
      subMenu.classList.toggle("expanded");

      if (!pressed) {
        subMenuTrigger.style.transform = "rotate(180deg)";
      } else {
        subMenuTrigger.style.transform = "none";
      }
    }

    // listen for click, enter key, or space key
    subMenuTrigger.addEventListener("click", toggleSubMenu);
    subMenuTrigger.addEventListener("keypress", (e) => {
      if (e.key === "Enter" || e.which === 13 || e.key === " " || e.which === 32) {
        e.preventDefault();
        toggleSubMenu();
      }
    });
  });
}
