import axios from 'axios';
import dialogPolyfill from 'dialog-polyfill';

const contactBlockForms = document.querySelectorAll('form.contact-block');

const toggleSubmitButton = (form, enabled) => {
  const submitButtons = form.querySelectorAll('button[type=submit]');
  Array.from(submitButtons).forEach(submitButton => {
    submitButton.disabled = !enabled;
    if (!enabled && submitButton.dataset.submitlabel) {
      submitButton.innerText = submitButton.dataset.submitlabel;
    } else {
      submitButton.innerText = 'Send';
    }
  });
}

const showSuccessModal = modal => {
  modal.showModal();
  // prevent background scroll
  document.querySelector('body').classList.add('modal-open');
  document.querySelector('html').classList.add('modal-open');

  const closeButtons = modal.querySelectorAll(".js-cancellation-modal-close");
  Array.from(closeButtons).forEach(button => {
    button.addEventListener("click", () => {
      modal.close();
      document.querySelector("body").classList.remove("modal-open");
      document.querySelector("html").classList.remove("modal-open");
    });
  });
}

const addFieldError = (field, errors) => {
  const input = document.querySelector(`input[name=${field}],select[name=${field}],textarea[name=${field}]`);
  input.parentElement.classList.add('error');
  errors.forEach(error => {
    const errorElement = document.createElement('p');
    errorElement.classList.add('error-message');
    errorElement.innerText = error;
    input.parentNode.insertBefore(errorElement, input.nextSibling);
  });
}

const clearErrors = () => {
  Array.from(document.querySelectorAll('.form-input.error')).forEach(element => {
    element.classList.remove('error');
  });
  Array.from(document.querySelectorAll('p.error-message')).forEach(element => {
    element.remove();
  });
}

if (contactBlockForms) {
  Array.from(contactBlockForms).forEach(form => {
    form.addEventListener('submit', event => {
      event.preventDefault();

      const formData = new FormData(event.target);
      const successModal = document.querySelector('#contact-form-success-modal');
      dialogPolyfill.registerDialog(successModal);

      clearErrors();

      axios.post('/api/v2/contact-form/', formData)
        .then(response => {

          showSuccessModal(successModal);

          const commentField = document.querySelector('#contact-block-textarea');
          commentField.value = '';

          toggleSubmitButton(form, true);
        })
        .catch(error => {
          if (error.response && error.response.data) {
            const errors = error.response.data;
            for (const property in errors) {
              addFieldError(property, errors[property]);
            }
          }
          toggleSubmitButton(form, true);
        });

      toggleSubmitButton(form, false);
    });
  });
}
