
import 'core-js/stable';
import 'whatwg-fetch';
import objectFitImages from 'object-fit-images';
import smoothscroll from 'smoothscroll-polyfill';
import 'classlist-polyfill';
import 'flatpickr';
import 'intersection-observer';
import 'url-search-params-polyfill';

import './images';
import './banner';
import './barcodes';
import './articleCardSlider';
import './cancellationModal';
import './carouselHeader';
import './categoryToggle';
import './contactForm';
import './dailyScheduleFilters';
import './datepicker';
import './donateWidget';
import './donationFrequency';
import './dropcaps';
import './floatlabel';
import './form';
import './formButtons';
import './ga';
import './hoursBlock';
import './interactiveStoryEmbed';
import './interactiveStoryModal';
import './interactiveStoryNav';
import './lazyload';
import './mapBlocks';
import './mapPage';
import './membershipToggle';
import './menus';
import './mainmenu';
import './multiCheckbox';
import './navMobile';
import './navScroll';
import './orderHistoryToggle';
import './randomizeColor';
import './randomizeIconShape';
import './signin';
import './tabs';
import './ticketModal';
import './visitBar';
import './webcam';
import './cartCount';
import './print';
//import './accountSavedPayments';
import './search';

// Polyfill object-fit
objectFitImages();
// Polyfill scrollTo / scrollIntoView
smoothscroll.polyfill();
