// Get all elements with submenus
const elementsWithSubmenus = document.querySelectorAll('.js-has-submenu');
// Amount of time to delay closing the submenu for people with reduced dexterity
const closeDelay = 1000 // milliseconds

// Abstract these functions since we use them a lot
function open(elem, submenu) {
  // close all others
  Array.from(elementsWithSubmenus).forEach(el => {
    el.setAttribute('aria-expanded', 'false');
    const subEl = document.getElementById(el.getAttribute('aria-controls'));
    subEl.classList.remove('open');
  });
  // open target
  submenu.classList.add('open');
  elem.setAttribute('aria-expanded', 'true');
}

function close(elem, submenu) {
  submenu.classList.remove('open');
  elem.setAttribute('aria-expanded', 'false');
}

//Loop over all elements that need submenu functionality.
Array.from(elementsWithSubmenus).forEach((elem) => {
  // Get the submenu
  const submenu = document.getElementById(elem.getAttribute('aria-controls'));
  // Get all the subnav links so we can control the submenu state for keyboard navigation
  const links = submenu.querySelectorAll('a');
  // Set up a timer variable for us to set and clear later
  let timer = null;
  // Toggle submenu on hover over menu button
  elem.addEventListener('mouseover', function() {
    open(elem, submenu);
    clearTimeout(timer);
  });
  // same behavior for keyboard navigation via tabbing
  elem.addEventListener('focus', function() {
    open(elem, submenu);
    clearTimeout(timer);
  });

  elem.addEventListener('mouseout', function() {
    // Start a timer to close the submenu after a delay, for people with reduced dexterity
    timer = setTimeout(() => {
      close(elem, submenu)
    }, closeDelay);
  });

  // Toggle submenu when hovering over the submenu itself
  submenu.addEventListener('mouseover', function() {
    open(elem, submenu);
    clearTimeout(timer);
  });

  submenu.addEventListener('mouseout', function() {
    timer = setTimeout(() => {
      close(elem, submenu)
    }, closeDelay);
  });


  // Toggle the submenu when clicking the menu button
  elem.addEventListener('click', function() {
    if (submenu.classList.contains('open')) {
      close(elem, submenu);
    } else {
      open(elem, submenu);
    }
  });
  Array.from(links).forEach(link => {
    // If a menu link is blurred (unfocused) start a timer to close the submenu
    link.addEventListener('blur', function() {
      timer = setTimeout(() => {
        close(elem, submenu)
      }, closeDelay);
    });
    // Prevent the submenu from closing when links are focused (after another link is blurred, i.e. tabbing through the menu)
    link.addEventListener('focus', function() {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
    });
  });
});
